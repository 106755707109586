import { useContext } from 'react';

import { BrandingContext, Branding } from './BrandingContext';

export const useBranding = (): Partial<Branding> => {
  const context = useContext(BrandingContext);

  if (!context) {
    throw new Error('useBranding must be used within a BrandingProvider.');
  }

  return context;
};
