// eslint-disable-next-line anti-trojan-source/no-bidi
import { Dispatch, SetStateAction } from 'react';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';
import { Typography, Hidden, Grid, makeStyles, Box } from '@material-ui/core';
import {
  HintButton,
  TextField,
  PhoneField,
  NaicsAutocompleteField,
  TinField,
  Select,
  AddressField,
  Switch,
  GridBox,
  GridRow,
  GridCol,
  PrimaryButton,
  CurrencyField,
  createErrorFormatter,
  removeEmpty,
  HelperText,
} from '@streetshares/frontend-common';
import { makeValidate } from 'mui-rff';
import { set } from 'lodash';
import * as Yup from 'yup';
import createFocusOnErrorDecorator from 'final-form-focus';
import { useMountedState } from 'react-use';
import useSWR from 'swr';
import { Prompt } from 'react-router-dom';

import { ReadableEligibilityReasons } from 'constants/eligibility';
import { Business } from 'Types/app';
import {
  businessNameProperty,
  businessDBAProperty,
  businessPhoneProperty,
  businessWebsiteProperty,
  businessIndustryProperty,
  businessEntityTypeProperty,
  businessEmployeeCountProperty,
  businessTinProperty,
  businessAnnualRevenueProperty,
  businessAddressOwnershipProperty,
  businessAddressOwnershipOptions,
  businessEntityTypeOptions,
  businessAddress1Property,
  businessAddress2Property,
  businessManualEntryProperty,
  businessCityProperty,
  businessStateProperty,
  businessZipcodeProperty,
  BusinessInfoLabels,
  BusinessInfoPathToName,
  businessStateOfFormationProperty,
  businessTinTypeProperty,
  businessAgeProperty,
  businessIsSeasonalProperty,
  stateOfFormationOptions,
  tinTypeOptions,
  employeeCountOptions,
  businessAgeOptions,
} from 'constants/formFields/businessFormFields';
import { useAuth } from 'auth/useAuth';
import { States, TinKeys, BusinessAddressOwnershipKeys, BusinessEntityTypes, BusinessAgeTypeKeys } from 'Types/enums';
import { config } from 'config';
import { businessPutRequest, createBusiness } from 'api/businessApi';
import { ApiEndpoint } from 'api/businessEndpoints';
import { poBoxRegex } from 'utils/validationRegex';

const useStyles = makeStyles({
  formContainer: {
    paddingRight: '0.6rem',
  },
  gridCol: {
    display: 'flex',
    paddingRight: '0 !important',
  },
  gridColHintButton: {
    padding: '0 !important',
  },
  gridRow: {
    marginBottom: '1.875rem',
  },
  verticalAlign: {
    display: 'block',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  saveButtonWrapper: {
    marginTop: '3rem',
    marginBottom: '3rem',
  },
  saveButton: {
    maxWidth: 150,
    minWidth: 150,
  },
});

type FormValues = Business;

export const schema = Yup.object({
  [businessNameProperty]: Yup.string().trim().required(),
  [businessAddress1Property]: Yup.string()
    .trim()
    .required()
    .test('notPoBox', 'P.O. Boxes are not accepted', (value) => Boolean(value?.search(poBoxRegex))),
  [businessAddress2Property]: Yup.string(),
  [businessCityProperty]: Yup.string().trim().required('City is a required field.'),
  [businessStateProperty]: Yup.string().trim().required('State is a required field.'),
  [businessZipcodeProperty]: Yup.string()
    .trim()
    .matches(/^\d{5}$/, '5-digit Zip Code is required.'),
  [businessAddressOwnershipProperty]: Yup.mixed().oneOf(Object.values(BusinessAddressOwnershipKeys)).required(),
  [businessPhoneProperty]: Yup.string()
    .matches(/^\(?(\d{3})\)?-?(\d{3})-?(\d{4})$/, 'You must provide a valid phone.')
    .required(),
  [businessWebsiteProperty]: Yup.string().matches(
    /((https?):\/\/)?(www.)?[\da-z]+(\.[a-z]{2,}){1,3}(#?\/?[\d#A-Za-z]+)*\/?(\?[\w-]+=[\d%A-Za-z-]+&?)?$/,
    'Website must be a valid url.',
  ),
  [businessIndustryProperty]: Yup.string().trim().required('You must provide a valid NAICS code'),
  [businessEntityTypeProperty]: Yup.mixed().oneOf(Object.keys(BusinessEntityTypes)).required(),
  [businessTinTypeProperty]: Yup.mixed().oneOf(Object.values(TinKeys)).required(),
  [businessTinProperty]: Yup.string().trim().required(),
  [businessStateOfFormationProperty]: Yup.mixed().oneOf(States).required(),
  [businessEmployeeCountProperty]: Yup.number().required(),
  [businessAnnualRevenueProperty]: Yup.string().required(),
  [businessAgeProperty]: Yup.mixed()
    .oneOf(Object.keys(BusinessAgeTypeKeys))
    .required('Business Age field is required.'),
});

const validate = makeValidate(schema, createErrorFormatter(BusinessInfoPathToName));
const focusOnErrorsDecorator = createFocusOnErrorDecorator<FormValues>();

const addressProperties = {
  address1: businessAddress1Property,
  address2: businessAddress2Property,
  city: businessCityProperty,
  state: businessStateProperty,
  zipcode: businessZipcodeProperty,
};

type Eligibility = {
  geography?: {
    result?: boolean | null;
    reason?: string | null;
  };
  industry?: {
    result?: boolean | null;
    reason?: string | null;
  };
  business_age?: {
    result?: boolean | null;
    reason?: string | null;
  };
  business_type?: {
    result?: boolean | null;
    reason?: string | null;
  };
  annual_revenue?: {
    result?: boolean | null;
    reason?: string | null;
  };
};

type BusinessInfoFormProps = {
  businessId: string;
  isBusinessEligible: boolean | null;
  setIsBusinessEligible: Dispatch<SetStateAction<boolean | null>>;
  setIneligibilityReasons?: Dispatch<SetStateAction<string[]>>;
  setNewBusinessId: Dispatch<SetStateAction<number | undefined>>;
};

export const BusinessForm: React.FC<BusinessInfoFormProps> = ({
  businessId,
  setIsBusinessEligible,
  isBusinessEligible,
  setIneligibilityReasons = () => {},
  setNewBusinessId,
}) => {
  const { data: business = {} as Business, mutate } = useSWR<Business>(
    businessId ? `${ApiEndpoint.business}/${businessId}` : null,
  );
  const classes = useStyles();
  const { user: { token = '' } = {} } = useAuth();
  const isMounted = useMountedState();

  const initialValues: FormValues = business;

  return (
    <FinalForm<FormValues>
      keepDirtyOnReinitialize
      initialValues={removeEmpty(initialValues)}
      validate={async (values) => {
        if (!isMounted) {
          return {};
        }

        const errors = await validate(values);

        if (values?.annual_revenue && Number(values.annual_revenue) === 0) {
          set(errors, businessAnnualRevenueProperty, ['Annual Revenue must be greater than zero']);
        }

        const phoneErrors = errors?.phone;
        const phoneErrorsLength = Array.isArray(phoneErrors) ? phoneErrors.length : 0;
        if (Array.isArray(phoneErrors) && phoneErrors.length > 1) {
          const lastErrorIndex = phoneErrorsLength - 1;
          set(errors, businessPhoneProperty, [phoneErrors[lastErrorIndex]]);
        }

        return errors;
      }}
      subscription={{ submitting: true }}
      decorators={[focusOnErrorsDecorator]}
      onSubmit={async (values) => {
        const newValues = {
          ...values,
          is_seasonal: values?.is_seasonal ?? false,
          address2: values?.address2 ?? null,
          website: values?.website ?? null,
          persons: values?.persons ?? [],
          number_of_employees: Number(values?.number_of_employees),
        };
        // Converts "" values to null, since BE does not allow ""
        const businessValues = Object.fromEntries(
          Object.entries(newValues).map(([key, value]) => (value === '' ? [key, null] : [key, value])),
        );

        const createBusinessPostReq = createBusiness(token, businessValues as FormValues);
        const updateBusinessPutReq = businessPutRequest(businessValues as FormValues, token);

        await fetch(
          businessId ? updateBusinessPutReq.url : createBusinessPostReq.url,
          businessId ? updateBusinessPutReq : createBusinessPostReq,
        )
          .then((res) => res.json())
          .then((response: Business) => {
            mutate(response, true)
              .then(() => {
                if (response?.is_eligible) {
                  setIsBusinessEligible(true);
                  // check if it's a new business and set id for parent component
                  if (!businessId) {
                    setNewBusinessId(response.id);
                  }
                } else {
                  const checks = response.eligibility_checks || {};
                  const reasons: string[] = [];
                  Object.keys(checks).map((key) => {
                    return (
                      checks &&
                      !checks[key as keyof Eligibility]?.result &&
                      reasons.push(ReadableEligibilityReasons[key])
                    );
                  });
                  setIsBusinessEligible(false);
                  setIneligibilityReasons(reasons);
                }
              })
              .finally(() => {});
          });
      }}
    >
      {({ handleSubmit, submitting }) => {
        return (
          <>
            <FormSpy subscription={{ values: true }}>
              {({ values }) => (
                <Typography variant="h1" className={classes.gridRow}>
                  {values.name}‎‎‏‏‎
                </Typography>
              )}
            </FormSpy>
            <Typography variant="body1" className={classes.gridRow}>
              Please fill up the initial data about your business. This will help provide accurate info about your
              application
            </Typography>
            <form onSubmit={handleSubmit}>
              {isBusinessEligible && ( // to avoid the prompt when the ineligibility modal jumps
                <FormSpy subscription={{ dirty: true, submitting }}>
                  {({ dirty }) => (
                    <Prompt when={dirty && !submitting} message="Are you sure? Any unsaved fields will be lost." />
                  )}
                </FormSpy>
              )}

              <GridBox borderColor="info.light" className={classes.formContainer} marginRight={5} paddingY={3}>
                <GridRow className={classes.gridRow}>
                  <GridCol className={classes.gridCol} cols={11}>
                    <TextField
                      label={BusinessInfoLabels.BUSINESS_NAME}
                      name={businessNameProperty}
                      placeholder="Business Legal Name"
                    />
                  </GridCol>
                </GridRow>

                <GridRow className={classes.gridRow}>
                  <GridCol className={classes.gridCol} cols={11}>
                    <TextField label={BusinessInfoLabels.BUSINESS_DBA} name={businessDBAProperty} placeholder="DBA" />
                  </GridCol>
                </GridRow>

                <GridRow className={classes.gridRow}>
                  <GridCol className={classes.gridCol} cols={11}>
                    <AddressField
                      label={BusinessInfoLabels.BUSINESS_ADDRESS1}
                      properties={addressProperties}
                      smartyStreetsApiKey={config.smartyStreetsApiKey}
                      manualEntryFlagProperty={businessManualEntryProperty}
                    />
                  </GridCol>
                </GridRow>

                <GridRow className={classes.gridRow}>
                  <GridCol className={classes.gridCol} cols={5}>
                    <Typography className={classes.verticalAlign}>Is this location owned or leased?</Typography>
                  </GridCol>
                  <GridCol className={classes.gridCol} cols={6}>
                    <Select
                      label={businessAddressOwnershipOptions[0].label}
                      name={businessAddressOwnershipProperty}
                      data={businessAddressOwnershipOptions}
                    />
                  </GridCol>
                </GridRow>

                <GridRow className={classes.gridRow}>
                  <GridCol className={classes.gridCol} cols={11}>
                    <PhoneField
                      label={BusinessInfoLabels.BUSINESS_PHONE}
                      name={businessPhoneProperty}
                      placeholder="(000) 000-000"
                    />
                  </GridCol>
                </GridRow>

                <GridRow className={classes.gridRow}>
                  <GridCol className={classes.gridCol} cols={11}>
                    <TextField
                      label={BusinessInfoLabels.BUSINESS_WEBSITE}
                      name={businessWebsiteProperty}
                      placeholder="Website URL"
                      fieldProps={{
                        parse: (value: string): string => value,
                      }}
                    />
                  </GridCol>
                </GridRow>

                <GridRow>
                  <GridCol className={classes.gridCol} cols={11}>
                    <NaicsAutocompleteField
                      label={BusinessInfoLabels.NAICS_CODE}
                      name={businessIndustryProperty}
                      placeholder="Enter code or name..."
                      formValueMode="code"
                    />
                  </GridCol>
                  <GridCol className={`${classes.gridColHintButton} ${classes.verticalAlign}`} cols={1}>
                    <HintButton
                      id="hint-button-bi-business-industry"
                      hintTitle={BusinessInfoLabels.NAICS_CODE}
                      hintDescription="Begin typing either the industry type for the Business, i.e. bakery or restaurant, or the NAICs code for the Business."
                    />
                  </GridCol>
                  <GridCol className={classes.gridCol} cols={1}>
                    <Hidden>
                      <Grid item />
                    </Hidden>
                  </GridCol>
                </GridRow>

                <GridRow className={classes.gridRow}>
                  <GridCol className={classes.gridCol} cols={11}>
                    <Select
                      label={BusinessInfoLabels.BUSINESS_ENTITY_TYPE}
                      name={businessEntityTypeProperty}
                      data={businessEntityTypeOptions}
                    />
                  </GridCol>
                  <GridCol className={`${classes.gridColHintButton} ${classes.verticalAlign}`} cols={1}>
                    <HintButton
                      id="hint-button-bi-business-industry"
                      hintTitle="Business Type"
                      hintDescription="The business type associated with the legal documents filed when your business was formed."
                    />
                  </GridCol>
                </GridRow>

                <GridRow className={classes.gridRow}>
                  <GridCol className={classes.gridCol} cols={11}>
                    <Select
                      label={BusinessInfoLabels.BUSINESS_TIN_TYPE}
                      name={businessTinTypeProperty}
                      data={tinTypeOptions}
                    />
                  </GridCol>
                </GridRow>

                <GridRow className={classes.gridRow}>
                  <GridCol className={classes.gridCol} cols={11}>
                    <Field name={businessTinTypeProperty} subscription={{ value: true }}>
                      {({ input: tinTypeInput }) => (
                        <TinField
                          label={BusinessInfoLabels.BUSINESS_TIN}
                          name={businessTinProperty}
                          tinType={tinTypeInput.value as string}
                          isPIIField
                          isSSNMasked
                        />
                      )}
                    </Field>
                  </GridCol>
                </GridRow>

                <GridRow className={classes.gridRow}>
                  <GridCol className={classes.gridCol} cols={11}>
                    <Select
                      label={BusinessInfoLabels.FORMATION_STATE}
                      name={businessStateOfFormationProperty}
                      data={stateOfFormationOptions}
                    />
                  </GridCol>
                  <GridCol className={`${classes.gridColHintButton} ${classes.verticalAlign}`} cols={1}>
                    <HintButton
                      id="hint-button-bi-business-industry"
                      hintTitle="State of Formation"
                      hintDescription="The state where the legal documents were filed to form your business."
                    />
                  </GridCol>
                </GridRow>

                <GridRow className={classes.gridRow}>
                  <GridCol className={classes.gridCol} cols={11}>
                    <Box width="100%">
                      <Select
                        data={businessAgeOptions}
                        name={businessAgeProperty}
                        label={BusinessInfoLabels.BUSINESS_AGE}
                      />
                      <HelperText>Age rounded (up or down) to the nearest year.</HelperText>
                    </Box>
                  </GridCol>
                  <GridCol className={`${classes.gridColHintButton} ${classes.verticalAlign}`} cols={1} />
                </GridRow>

                <GridRow className={classes.gridRow}>
                  <GridCol className={classes.gridCol} cols={12}>
                    <Switch
                      name={businessIsSeasonalProperty}
                      color="primary"
                      optionLabel={BusinessInfoLabels.BUSINESS_IS_SEASONAL}
                    />
                  </GridCol>
                </GridRow>

                <GridRow className={classes.gridRow}>
                  <GridCol className={classes.gridCol} cols={11}>
                    <Select
                      label={BusinessInfoLabels.BUSINESS_EMPLOYEE_COUNT}
                      name={businessEmployeeCountProperty}
                      data={employeeCountOptions}
                    />
                  </GridCol>
                </GridRow>

                <GridRow className={classes.gridRow}>
                  <GridCol className={classes.gridCol} cols={11}>
                    <CurrencyField
                      name={businessAnnualRevenueProperty}
                      label={BusinessInfoLabels.BUSINESS_ANNUAL_REVENUE}
                      decimalScale={2}
                    />
                  </GridCol>
                </GridRow>
              </GridBox>
              <GridRow className={classes.saveButtonWrapper}>
                <GridCol>
                  <PrimaryButton className={classes.saveButton} disabled={submitting} onClick={handleSubmit}>
                    Save
                  </PrimaryButton>
                </GridCol>
              </GridRow>
            </form>
          </>
        );
      }}
    </FinalForm>
  );
};
