import { FC, useState } from 'react';
import { Container, makeStyles, Typography } from '@material-ui/core';
import { GridRow, GridCol, NeedAssistance } from '@streetshares/frontend-common';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { useParams, Link } from 'react-router-dom';

import { useBranding } from 'branding/useBranding';
import { Layout } from 'components/Layout/Layout';
import { BusinessForm } from 'components/Common/BusinessForm';
import { ModalOfIneligibility } from 'components/Common/ModalOfIneligibility';
import { AuthenticatedAppRoutes } from 'routes/AppRoutes';
import { useProductConfig } from 'api/productConfig';
import { ProductSettingsBLX, ProductTypes } from 'Types/enums';
import { ModalOfEligibility } from 'components/Common/ModalOfEligibility';

export const useStyles = makeStyles(({ palette, breakpoints }) => ({
  container: {
    borderTop: `1px solid ${palette.common.white}`,
    borderBottom: `1px solid ${palette.common.white}`,
  },
  mbContact: {
    [breakpoints.down('md')]: {
      marginBottom: '2.5rem',
    },
  },
  LinkContainer: {
    paddingBottom: 0,
    marginTop: '0.5rem',
  },
  linkText: {
    fontWeight: 600,
    fontFamily: 'Nunito Sans',
    marginLeft: 5,
  },
  link: {
    display: 'flex',
    margin: '8px 0',
    color: '#2087F4',
    '&:hover': {
      color: '#1244AF',
    },
  },
}));

export const BusinessFormScreen: FC = () => {
  const classes = useStyles();
  const { contactEmail, contactPhoneNumber } = useBranding();
  const { businessId }: { businessId: string } = useParams();
  const [isBusinessEligible, setIsBusinessEligible] = useState<boolean | null>(null);
  const [ineligibilityReasons, setIneligibilityReasons] = useState(['None']);
  const [newBusinessId, setNewBusinessId] = useState<number | undefined>();
  const {
    productConfig: { settings },
  } = useProductConfig(ProductTypes.BLX);

  return (
    <Layout>
      <ModalOfIneligibility
        isOpen={isBusinessEligible !== null ? !isBusinessEligible : false}
        ineligibilityReasons={ineligibilityReasons}
        setIsBusinessEligible={setIsBusinessEligible}
      />
      <ModalOfEligibility isOpen={isBusinessEligible ?? false} businessId={newBusinessId?.toString() ?? businessId} />
      <Container className={classes.container} maxWidth="lg">
        <GridRow spacing={2} container>
          <GridCol className={classes.LinkContainer}>
            <Link className={classes.link} to={AuthenticatedAppRoutes.Home}>
              <KeyboardBackspaceIcon />
              <Typography variant="body2" className={classes.linkText}>
                Back to Dashboard
              </Typography>
            </Link>
          </GridCol>
        </GridRow>
        <GridRow spacing={6} container>
          <GridCol item sm={12} md={8} lg={8}>
            <BusinessForm
              businessId={businessId}
              isBusinessEligible={isBusinessEligible}
              setIsBusinessEligible={setIsBusinessEligible}
              setIneligibilityReasons={setIneligibilityReasons}
              setNewBusinessId={setNewBusinessId}
            />
          </GridCol>
          <GridCol className={classes.mbContact} item sm={12} md={4} lg={4}>
            <NeedAssistance
              email={settings[ProductSettingsBLX.SUPPORT_EMAIL] ?? contactEmail}
              phone={settings[ProductSettingsBLX.SUPPORT_PHONE] ?? contactPhoneNumber ?? undefined}
            />
          </GridCol>
        </GridRow>
      </Container>
    </Layout>
  );
};
