import React, { useEffect, useState } from 'react';
import { withLDProvider, useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { datadogLogs, StatusType } from '@datadog/browser-logs';
import { HintDrawerProvider, Loader, logger, CustomerThemeProvider, ErrorDialog } from '@streetshares/frontend-common';
import { useCookie, useFavicon } from 'react-use';
import { Helmet } from 'react-helmet';

import { AuthProvider } from 'auth/AuthProvider';
import { BrandingProvider } from 'branding/BrandingProvider';
import { useFetch } from 'utils/useFetch';
import { config, EnvironmentName } from 'config';
import { BrandResponse } from 'Types/member';
import { SelectRouter } from 'routes/SelectRouter';
import { AppWrapper } from 'components/Layout/AppWrapper';

/* This is a way to override the brand url for a specific environment. */
const brandUrlOverrides: Partial<Record<EnvironmentName, string>> = {
  dev: 'member',
  okteto: 'fbr',
};

/* This is a request to the backend to get the brand information for the current organization. */
const brandRequest = {
  url: `${config.apiBaseURL}/member/v2/brand/v2?url=${
    brandUrlOverrides[config.environmentName] ?? window.location.origin
  }`,
};
function App() {
  const { response, loading, error } = useFetch<BrandResponse>(brandRequest);
  const [muiThemeTypeCookie, setMuiThemeTypeCookie] = useCookie('mui-theme-type');
  const ldClient = useLDClient();
  const [ldLoaded, setLdLoaded] = useState(false);

  const {
    customerHubFrontendLoggingLevel,
    enableCustomerHub,
  }: { customerHubFrontendLoggingLevel: string; enableCustomerHub: boolean } = useFlags();

  useEffect(() => {
    window.console.log(`Setting logging level to ${customerHubFrontendLoggingLevel}.`);
    datadogLogs.logger.setLevel(customerHubFrontendLoggingLevel as StatusType);
  }, [customerHubFrontendLoggingLevel]);

  useEffect(() => {
    if (response?.organization && !error) {
      ldClient
        ?.identify(
          { key: '1', anonymous: true, custom: { organizationId: response?.organization?.id } },
          undefined,
          (err, flags) => {
            if (err) {
              logger.error('Error identifying the user in LaunchDarkly', { serverMessage: err });
            } else {
              logger.debug('LaunchDarkly successfully loaded.', { flags });
              setLdLoaded(true);
            }
          },
        )
        ?.finally(() => {});
    }
  }, [ldClient, response, response?.organization?.id, error]);

  const faviconUrl = response?.favicon_url !== undefined ? response.favicon_url : '';
  useFavicon(faviconUrl);

  if (error) {
    return <ErrorDialog open={Boolean(error)} />;
  }

  if (!response || !response.organization || loading) {
    return <div>Loading...</div>;
  }

  const {
    logo_url: logoUrl = '',
    organization: {
      id: organizationId,
      name: partnerName,
      brand_url: brandUrl = '',
      privacy_policy_url: privacyPolicyUrl = '',
      contact_email: contactEmail = '',
      contact_phone_number: contactPhoneNumber = '',
    },
  } = response;

  datadogLogs.addLoggerGlobalContext('partnerName', partnerName);
  datadogLogs.addLoggerGlobalContext('organizationId', organizationId);

  if (!ldLoaded) {
    return <Loader message="Loading data..." />;
  }

  // Since we wait for the LD identify call to complete, we know that this will be the correct org-specific value here.
  if (enableCustomerHub === false) {
    return <div>This feature is not yet enabled.</div>;
  }

  return (
    <CustomerThemeProvider brand={response}>
      <CssBaseline />
      <BrandingProvider
        logoUrl={logoUrl}
        brandUrl={brandUrl}
        privacyPolicyUrl={privacyPolicyUrl}
        organizationId={organizationId}
        partnerName={partnerName}
        contactEmail={contactEmail}
        contactPhoneNumber={contactPhoneNumber}
        muiThemeTypeCookie={muiThemeTypeCookie}
        setMuiThemeTypeCookie={setMuiThemeTypeCookie}
      >
        <AuthProvider organizationId={organizationId}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <HintDrawerProvider>
              <AppWrapper>
                <SelectRouter />
                <Helmet>
                  <link rel="icon" href={faviconUrl} />
                  <link rel="mask-icon" href={faviconUrl} />
                  <link rel="apple-touch-icon" href={faviconUrl} />
                </Helmet>
              </AppWrapper>
            </HintDrawerProvider>
          </MuiPickersUtilsProvider>
        </AuthProvider>
      </BrandingProvider>
    </CustomerThemeProvider>
  );
}

export const CustomerHubApp = withLDProvider({
  clientSideID: config.launchdarklyID,
  options: {
    allAttributesPrivate: true,
    sendEventsOnlyForVariation: true,
    logger: {
      debug(message) {
        logger.debug(message);
      },
      info(message) {
        logger.info(message);
      },
      warn(message) {
        logger.warn(message);
      },
      error(message) {
        logger.error(message);
      },
    },
  },
  context: {
    kind: 'user',
    key: '1',
    anonymous: true,
  },
})(App);
