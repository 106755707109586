import { config } from 'config';

export const fetchSystemStatus = async () => {
  const url = config.systemStatusUrl;
  const authToken = config.systemStatusPageAuthToken;

  const response = await fetch(url, { headers: { Authorization: authToken } });

  if (!response.ok) {
    throw new Error('Failed fetching system status.');
  }

  return response.json();
};
