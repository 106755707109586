/* eslint-disable react/no-children-prop */
// Rule above disabled beacuse children= is the recommended syntax in react-router 5.1, gets replaced with element= in 6.0
import React, { FC } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { LoginScreen } from 'screens/LoginScreen';
import { UnauthenticatedAppRoutes } from 'routes/AppRoutes';
import { useBranding } from 'branding/useBranding';

import { Route } from './Route';

export const Router: FC = () => {
  const { partnerName = '' } = useBranding();

  return (
    <Switch>
      <Route
        exact
        path={UnauthenticatedAppRoutes.Signin}
        children={<LoginScreen />}
        title={`${partnerName} Hub - Sign In`}
      />
      <Redirect to={UnauthenticatedAppRoutes.Signin} />
    </Switch>
  );
};
