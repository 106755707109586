import { logger, SessionUser, UpdateUserData } from '@streetshares/frontend-common';

import {
  emailProperty,
  firstNameProperty,
  lastNameProperty,
  passwordProperty,
} from 'constants/formFields/loginFormFields';
import { MemberInfo, MFAMemberInfo, LoginResponse } from 'Types/member';
import { FetchOptions, getAuthHeaders } from 'api/fetch';
import { UTMParamsT } from 'utils/utm';
import { CredentialsT } from 'auth/persister';
import { config } from 'config';

type LoginUserData = {
  [emailProperty]: string;
  [passwordProperty]: string;
  organization_id: number;
  utm?: UTMParamsT;
};

type ResetPasswordData = {
  message: string;
};

const memberApiUrl = `${config.apiBaseURL}/member/v2/laas`;

export const createGetUserInfoRequest = ({ token }: CredentialsT): FetchOptions => ({
  url: `${memberApiUrl}/member/current`,
  method: 'GET',
  headers: getAuthHeaders(token),
});

export const createUserLoginRequest = (loginUserData: LoginUserData): FetchOptions => ({
  url: `${memberApiUrl}/login`,
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(loginUserData),
});

export async function resetPassword(email: string, organizationId: number): Promise<ResetPasswordData> {
  const resetPasswordResponse = await fetch(`${memberApiUrl}/password/reset`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      organization_id: organizationId,
    }),
  });

  if (!resetPasswordResponse.ok) {
    throw new Error('Failed to trigger password reset');
  }

  const data: ResetPasswordData = (await resetPasswordResponse.json()) as ResetPasswordData;

  return data;
}

export function getSessionUser(data: MemberInfo): SessionUser {
  const { member, token } = data;

  return {
    firstName: member[firstNameProperty],
    lastName: member[lastNameProperty],
    token,
    memberId: member.id,
    email: member[emailProperty],
    // TODO: stop using userId because it's the worst
    userId: member.user_id,
  };
}

export function getMFASessionUser(data: MFAMemberInfo) {
  const { member, token } = data;
  const mfa = data?.mfa;

  return {
    firstName: member[firstNameProperty],
    lastName: member[lastNameProperty],
    token,
    memberId: member.id,
    email: member[emailProperty],
    member,
    userId: member.user_id,
    mfa,
  };
}

export async function login(userData: LoginUserData): Promise<SessionUser> {
  const { url: loginUrl, ...loginOptions } = createUserLoginRequest(userData);
  const response = await fetch(loginUrl, loginOptions);
  if (!response.ok) {
    // eslint-disable-next-line unused-imports/no-unused-vars-ts
    const { password, ...memberPayload } = userData;
    logger.info('Failed to authenticate user', {
      serverMessage: await response.json(),
      memberPayload,
    });
    throw new Error('Failed to log in.');
  }
  const data: MemberInfo = (await response.json()) as MemberInfo;

  return getSessionUser(data);
}

export async function loginMFA(userData: LoginUserData): Promise<LoginResponse> {
  const { url: loginUrl, ...loginOptions } = createUserLoginRequest(userData);
  const response = await fetch(loginUrl, loginOptions);
  if (!response.ok) {
    // eslint-disable-next-line unused-imports/no-unused-vars-ts
    const { password, ...memberPayload } = userData;
    logger.info('Failed to authenticate user', {
      serverMessage: await response.json(),
      memberPayload,
    });
    throw new Error('Failed to log in.');
  }
  const data: MFAMemberInfo = (await response.json()) as MFAMemberInfo;

  return getMFASessionUser(data);
}

const createUserUpdateRequest = (updateUserData: UpdateUserData, token: string): FetchOptions => {
  return {
    url: '/member/v2/laas/member/current',
    method: 'PATCH',
    headers: getAuthHeaders(token),
    body: JSON.stringify(updateUserData),
  };
};

export async function updateLoggedInUser(userData: UpdateUserData, token: string): Promise<UpdateUserData> {
  const { url, ...options } = createUserUpdateRequest(userData, token);
  const response = await fetch(url, options);

  if (!response.ok) {
    logger.warn('Failed to update user', { message: await response.json() });
    throw new Error('Failed to update.');
  }

  return response.json() as UpdateUserData;
}

type AssociatePhoneNumberType = {
  phone_number: string;
  oob_channels: string[];
  authenticator_types: string[];
  member_id: number | null;
};

export const associatePhoneNumber = (associateUserData: AssociatePhoneNumberType) => {
  const url = `${memberApiUrl}/enroll/mfa`;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(associateUserData),
  };

  return fetch(url, options);
};

type LoginWithMFAType = {
  challenge_code: string;
  member_id: number | null;
};

export const loginWithMFA = (loginMFAData: LoginWithMFAType) => {
  const url = `${memberApiUrl}/login/mfa`;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(loginMFAData),
  };

  return fetch(url, options);
};

type ChallengeSMSType = { member_id: number | null; mfa_type: string; mfa_token: string };

export const challengeSMS = (challengeData: ChallengeSMSType) => {
  const url = `${memberApiUrl}/challenge/mfa`;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(challengeData),
  };

  return fetch(url, options);
};
