import { ReactElement } from 'react';
import { GridBox, GridRow, GridCol, formatDateNew, logger, PrimaryButton } from '@streetshares/frontend-common';
import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography, Box } from '@material-ui/core';
import { CreditCard } from '@material-ui/icons';

import { HubBusinessApplication, ProductsToApply } from 'Types/app';
import { BusinessLoanIcon } from 'assets/generated-svg-components/icons';
import { config } from 'config';
import { createApplicationBusinessIdRequest } from 'api/businessApi';
import { useAuth } from 'auth/useAuth';

import { EmailVerificationBox } from './EmailVerificationBox';
import { ApplicationMessageBox } from './ApplicationMessageBox';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  root: {
    width: '100%',
    height: '147px',
    [breakpoints.down('sm')]: {
      height: 'auto',
    },
    borderRadius: '8px',
    marginBottom: '30px',
    border: `1px solid ${palette.grey[300]}`,
    boxShadow: '0 2px 8px 0 rgba(0,0,0,0.3)',
    padding: '12px',
  },
  title: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginBottom: 0,
    paddingBottom: '0!important',
    fontSize: '1.25rem',
  },
  iconContainer: {
    backgroundColor: '#E8F3FD',
    width: '90px',
    maxWidth: '130px',
    height: '146px',
    borderRadius: '8px 0 0 8px',
    transform: 'translateY(-30px)',
    [breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
      textAlign: 'center',
    },
  },
  icon: {
    olor: palette.secondary.dark,
    transform: 'scale(1.8, 1.8)',
    margin: '30px 10px 20px 25px',
    [breakpoints.down('sm')]: {
      transform: 'scale(2.2, 2.2)',
    },
  },
  tableContainer: {
    paddingLeft: '30px!important',
    marginTop: '0px',
  },
  table: {
    [breakpoints.up('md')]: {
      width: '444px',
    },
    borderStyle: 'hidden',
    '& thead': {
      borderStyle: 'hidden',
    },
    '& th': {
      fontSize: '1.125rem',
      '&:first-of-type': {
        fontWeight: 'bold',
        [breakpoints.up('md')]: {
          width: '110px',
        },
      },
      paddingBottom: 0,
      paddingLeft: 0,
      [breakpoints.up('md')]: {
        width: '170px',
      },
    },

    '& td': {
      paddingTop: 0,
      paddingLeft: 0,
      fontSize: '0.75rem',
    },
  },
  rightArrowContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 'auto',
    marginBottom: 'auto',
    paddingBottom: '60px!important',
    [breakpoints.down('sm')]: {
      paddingBottom: '6px!important',
    },
    '& svg': {
      color: palette.grey[700],
    },
  },
  applicationMessageBoxContainer: {
    width: '100%',
    height: '30px',
    padding: '0',
  },
  withoutVerificationBox: {
    transform: 'translateY(-10px)',
  },
  primaryButton: {
    fontFamily: 'Roboto',
    marginRight: '30px',
    minWidth: '100px',
    height: '45px',
  },
}));

type ProductProps = {
  name: string;
  headers: Array<string>;
  icon: ReactElement;
};

type ProductCardProps = {
  productToApply: ProductsToApply;
  application: HubBusinessApplication;
  businessId: number | undefined;
};

const warningMessages = {
  documentRequest: 'Additional documents are needed to complete the review of your application.',
};

export const ProductCardReady: React.FC<ProductCardProps> = ({ productToApply, application, businessId }) => {
  const classes = useStyles();
  const { user: { token = '' } = {} } = useAuth();

  const {
    id: appId,
    status_for_customer: statusForCustomer,
    create_time: createTime,
    is_email_verified: isEmailVerified,
    has_guarantor: hasGuarantor,
    is_email_verification_requested: isEmailVerificationReq,
    has_document_requests: hasDocumentRequests,
  } = application;

  const hasEmailVerifcationBox = !isEmailVerified && isEmailVerificationReq && hasGuarantor;

  const products: Record<ProductsToApply, ProductProps> = {
    BLX: {
      name: 'Business Credit Application',
      headers: ['Application ID', 'Status', 'Creation Date'],
      icon: <BusinessLoanIcon className={classes.icon} />,
    },
    CREDIT_CARD: {
      name: 'Credit Card Application',
      headers: ['Product', 'Status', 'Approval date'],
      icon: <CreditCard className={classes.icon} />,
    },
  };

  const redirectToBLA = () => {
    const req = createApplicationBusinessIdRequest(appId, {}, token);
    fetch(req.url, req)
      .then(() => {
        window.location.assign(`${config.businessLoanAppPublicUrl}/${appId ?? ''}/home`);
      })
      .catch(() => {
        // being defensive we can redirect anyway without assigning the business previously
        logger.error('Business assination in SUMA redirect is failing');
        window.location.assign(`${config.businessLoanAppPublicUrl}/${appId ?? ''}/home`);
      });
  };

  return (
    <GridBox className={classes.root}>
      <GridRow container>
        <Box className={classes.applicationMessageBoxContainer}>
          {hasEmailVerifcationBox && <EmailVerificationBox businessId={businessId} />}
          {!hasEmailVerifcationBox && hasDocumentRequests && (
            <ApplicationMessageBox message={warningMessages.documentRequest} />
          )}
        </Box>

        <GridCol className={classes.iconContainer} md={2} lg={2}>
          {products[productToApply].icon}
        </GridCol>
        <GridCol
          className={`${classes.tableContainer} ${!hasEmailVerifcationBox ? classes.withoutVerificationBox : ''}`}
          item
          md={9}
          lg={9}
        >
          <Typography className={classes.title} component="p">
            {products[productToApply].name}
          </Typography>
          <Table className={classes.table} size="small" aria-label="history description table">
            <TableHead>
              <TableRow>
                <TableCell align="left">{appId}</TableCell>
                <TableCell align="left">{statusForCustomer}</TableCell>
                <TableCell align="left">{formatDateNew(createTime)}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {products[productToApply].headers.map((header) => (
                  <TableCell key={header} align="left">
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </GridCol>
        <GridCol
          className={`${classes.rightArrowContainer} ${!hasEmailVerifcationBox ? classes.withoutVerificationBox : ''}`}
          md={1}
          lg={1}
        >
          <PrimaryButton className={classes.primaryButton} onClick={redirectToBLA}>
            Continue
          </PrimaryButton>
        </GridCol>
      </GridRow>
    </GridBox>
  );
};
