import { Typography, makeStyles } from '@material-ui/core';
import { GridBox, GridRow, GridCol } from '@streetshares/frontend-common';
import { generatePath, Link } from 'react-router-dom';

import { HubBusiness, HubBusinessApplicationsGroup, ProductsToApply } from 'Types/app';
import { AuthenticatedAppRoutes } from 'routes/AppRoutes';
import { ProductsSection } from 'components/Common/ProductsSection';

const useStyles = makeStyles(({ palette }) => ({
  box: {
    border: `1px solid ${palette.info.light}`,
    padding: '30px',
    marginBottom: '2.75rem',
  },
  containerTitle: {
    display: 'flex',
  },
  businessName: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '24px',
    fontStyle: 'normal',
  },
  applications: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '20px',
    fontStyle: 'normal',
  },
  link: {
    marginLeft: 'auto',
    color: '#2087F4',
    '&:hover': {
      color: '#1244AF',
    },
  },
  linkText: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '16px',
    fontStyle: 'normal',
  },
}));

type BusinessContainerProps = {
  business: HubBusiness;
  businessApplicationGroup?: HubBusinessApplicationsGroup;
};

export const BusinessContainer: React.FC<BusinessContainerProps> = ({ business, businessApplicationGroup }) => {
  const classes = useStyles();

  const eligibleProducts: ProductsToApply[] = Object.keys(business?.products_eligibility ?? {}).filter(
    (key) => business?.products_eligibility && business?.products_eligibility[key],
  ) as ProductsToApply[];

  return (
    <GridBox className={classes.box}>
      <GridRow>
        <GridCol className={classes.containerTitle}>
          <Typography className={classes.businessName} variant="subtitle2">
            {business.name}
          </Typography>
          <Link
            className={classes.link}
            to={generatePath(AuthenticatedAppRoutes.Business, { businessId: business.id })}
          >
            <Typography variant="body2" className={classes.linkText}>
              Edit Business
            </Typography>
          </Link>
        </GridCol>
        <GridCol>
          <Typography variant="subtitle2" className={classes.applications}>
            Your Applications
          </Typography>
        </GridCol>
        <GridCol>
          <ProductsSection
            businessId={business.id}
            businessApplicationGroup={businessApplicationGroup}
            products={eligibleProducts}
          />
        </GridCol>
      </GridRow>
    </GridBox>
  );
};
