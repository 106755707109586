export const firstNameProperty = 'first_name';
export const lastNameProperty = 'last_name';
export const emailProperty = 'email';
export const passwordProperty = 'password';

export enum LoginLabels {
  CREATE_ACCOUNT = 'Create Account',
  EMAIL = 'Email',
  PASSWORD = 'Password',
  ARIA_RESET_PASSWORD = 'Reset password',
  NEW_USER_MESSAGE = 'Never been here before?',
}
