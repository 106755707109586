import { Container, makeStyles, Grid, Box, Typography } from '@material-ui/core';
import { rgba } from 'polished';
import { isNil } from 'lodash';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  estimateBoxWrapper: {
    padding: '1rem 0 .5rem 0',
    marginTop: '1rem',
    [breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
    [breakpoints.down('xs')]: {
      padding: '1rem',
    },
  },
  estimateBoxLabel: {
    fontSize: '2rem',
    fontWeight: 300,
  },
  businessNameWrapper: {
    [breakpoints.down('sm')]: {
      marginBottom: '1rem',
    },
  },
  estimateValueWrapper: {
    padding: 12,
    marginBottom: '0.5rem',
  },
  estimateWording: {
    marginRight: '0.5rem',
    fontSize: '0.875rem',
    fontWeight: 500,
    [breakpoints.down('xs')]: {
      marginBottom: '0.5rem',
    },
  },
  estimateValue: {
    fontSize: '2.5rem',
    fontWeight: 500,
    textAlign: 'right',
  },
  estimateValueCondition: {
    fontSize: '0.75rem',
    lineHeight: '1.2',
  },
  success: {
    backgroundColor: rgba(palette.success.main, 0.1),
  },
  progress: {
    backgroundColor: rgba(palette.info.main, 0.1),
  },
}));

type Props = {
  title?: string | null;
};
export const HeaderInfo: React.FC<Props> = ({ title }) => {
  const classes = useStyles();

  if (isNil(title)) {
    return null;
  }

  return (
    <Container maxWidth="lg">
      <Grid container alignItems="center" className={classes.estimateBoxWrapper}>
        <Grid item sm={12} md={6} lg={8}>
          <Box mb={0.25} className={classes.estimateBoxLabel}>
            <Typography variant="h1">Term Loan Application Hub</Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
