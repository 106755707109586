import { Dispatch, FC, SetStateAction } from 'react';
import { Typography, makeStyles, Box, Checkbox, OutlinedInput } from '@material-ui/core';
import { AuthorizationModal, GridCol, Logo, PrimaryButton, SecondaryButton } from '@streetshares/frontend-common';

export const useStyles = makeStyles(() => ({
  text: {
    fontSize: '1rem',
  },
  codeInput: {
    height: 46,
    width: 250,
    margin: 'auto',
  },
  copyButton: {
    width: 250,
  },
  checkbox: {
    marginTop: 4,
    display: 'flex',
    alignItems: 'center',
    width: 250,
    margin: 'auto',
  },
  logo: {
    margin: '0px 0px 16px',
    height: 'unset',
  },
  title: {
    fontSize: '1.5rem',
  },
}));

export type RecoveryCodeProps = {
  open: boolean;
  handleContinue: () => void;
  onClose: () => void;
  logoUrl?: string;
  recoveryCode: string;
  errorMessage?: string | null;
  checked: boolean;
  setChecked: Dispatch<SetStateAction<boolean>>;
};

type ActionsProps = {
  handleContinue: () => void;
  checked: boolean;
};

type HeaderProps = {
  logoUrl?: string;
};

type ContentProps = {
  checked: boolean;
  setChecked: Dispatch<SetStateAction<boolean>>;
  recoveryCode: string;
};

type ErrorProps = {
  errorMessage?: string | null;
};

const Header = ({ logoUrl }: HeaderProps) => {
  const recoveryClasses = useStyles();

  return (
    <div>
      <Logo src={logoUrl} className={recoveryClasses.logo} />
      <Typography className={recoveryClasses.title}>Almost There!</Typography>
    </div>
  );
};

const Content = ({ recoveryCode, checked, setChecked }: ContentProps) => {
  const recoveryClasses = useStyles();

  const copyToClipboard = () => {
    window.navigator.clipboard.writeText(recoveryCode).catch(() => {});
  };

  return (
    <GridCol>
      <Typography className={recoveryClasses.text}>
        {`Copy this recovery code and keep it somewhere safe. You'll need it if you ever need to log in without your
      device.`}
      </Typography>
      <Box pt={3} pb={2}>
        <OutlinedInput name="recovery_code" className={recoveryClasses.codeInput} disabled value={recoveryCode} />
      </Box>
      <Box>
        <SecondaryButton onClick={copyToClipboard} className={recoveryClasses.copyButton}>
          Copy code
        </SecondaryButton>
      </Box>
      <Box className={recoveryClasses.checkbox}>
        <Checkbox checked={checked} onChange={() => setChecked(!checked)} />{' '}
        <Typography className={recoveryClasses.text}>I have recorded this number</Typography>
      </Box>
    </GridCol>
  );
};

const Actions: FC<ActionsProps> = ({ handleContinue, checked }) => {
  return (
    <PrimaryButton
      style={{ width: 250 }}
      onClick={() => {
        handleContinue();
      }}
      disabled={!checked}
    >
      Continue
    </PrimaryButton>
  );
};

const ErrorMessage: FC<ErrorProps> = ({ errorMessage }) => {
  return <Typography>{errorMessage || ''}</Typography>;
};

export const RecoveryCodeModal: FC<RecoveryCodeProps> = ({
  open,
  handleContinue,
  onClose,
  logoUrl,
  recoveryCode,
  errorMessage,
  checked = false,
  setChecked,
}) => {
  return (
    <AuthorizationModal
      onClose={onClose}
      open={open}
      header={<Header logoUrl={logoUrl} />}
      content={<Content recoveryCode={recoveryCode} checked={checked} setChecked={setChecked} />}
      actions={<Actions handleContinue={handleContinue} checked={checked} />}
      error={<ErrorMessage errorMessage={errorMessage} />}
    />
  );
};
