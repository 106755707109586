import { useReducer, useEffect, useMemo } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { datadogRum } from '@datadog/browser-rum';
import { Loader, SessionUser } from '@streetshares/frontend-common';
import { datadogLogs } from '@datadog/browser-logs';

import { asyncReducer, LOADING_STATE } from 'reducers/async';
import { Member } from 'Types/member';
import { AuthContext } from 'auth/AuthContext';
import { clearCredentials, CredentialsT, loadCredentials, saveCredentials } from 'auth/persister';
import { logoutUser } from 'auth/logout';
import { createGetUserInfoRequest, getSessionUser } from 'api/apiAuth';
import { LendingApplication } from 'Types/app';
import { getAuthHeaders } from 'api/fetch';
import { ApiEndpoint } from 'api/businessEndpoints';

type Props = {
  organizationId: number;
};

type AllApplicationsResponse = {
  applications: Array<LendingApplication>;
};

export const AuthProvider: React.FC<Props> = ({ children, organizationId }) => {
  const [{ loading, data: userData, error }, dispatch] = useReducer(asyncReducer, LOADING_STATE);
  const ldClient = useLDClient();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const identify = ldClient?.identify;

  const identifyUser = useMemo(
    () => (user: SessionUser) => {
      // DataDog Logs
      datadogLogs.addLoggerGlobalContext('memberId', `${user.memberId}`);

      // DataDog RUM
      datadogRum.setUser({
        id: String(user.memberId),
        email: user.email,
      });

      // LaunchDarkly
      identify?.({ key: `${user.memberId}`, email: user.email, custom: { organizationId } }).finally(() => {});
    },
    [identify, organizationId],
  );

  useEffect(() => {
    const getUserInfo = async (credentials: CredentialsT): Promise<void> => {
      const { token } = credentials;

      dispatch({ type: 'FETCH_INIT' });
      const { url, ...options } = createGetUserInfoRequest(credentials);

      try {
        const getUserInfoResponse = await fetch(url, options);

        if (!getUserInfoResponse.ok) {
          throw new Error(`Failed to get user info: ${getUserInfoResponse.status}`);
        }

        const member: Member = (await getUserInfoResponse.json()) as Member;
        const sessionUser = getSessionUser({ member, token });

        const allAppsResponse = await fetch(`${ApiEndpoint.applications}`, {
          headers: getAuthHeaders(String(sessionUser?.token)),
        });

        const { applications }: AllApplicationsResponse = (await allAppsResponse.json()) as AllApplicationsResponse;

        // TODO: This will be replaced by proper app selection after the pilot, but for now we assume they only have 1 app
        const appId = applications?.[0]?.id;

        identifyUser(sessionUser);

        return dispatch({
          type: 'FETCH_SUCCESS',
          data: { ...sessionUser, appId },
        });
      } catch (e) {
        clearCredentials();
        if (!(e instanceof Error)) return undefined;
        return dispatch({ type: 'FETCH_FAILURE', error: e });
      }
    };

    const credentials = loadCredentials();
    if (credentials) {
      getUserInfo(credentials).finally(() => {});
    } else {
      dispatch({ type: 'FETCH_RESET' });
    }
  }, [identifyUser, organizationId]);

  const authContext = useMemo(
    () => ({
      organizationId,
      user: userData,
      error,
      loginUser: (user: SessionUser, appId: number | undefined): void => {
        saveCredentials(user);
        identifyUser(user);
        dispatch({ type: 'FETCH_SUCCESS', data: { ...user, appId } });
      },
      logoutUser,
    }),
    [error, identifyUser, organizationId, userData],
  );

  if (loading) {
    return <Loader message="Fetching user profile..." />;
  }

  return <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>;
};
