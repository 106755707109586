import { config } from 'config';

export const changePassword = (oldPassword: string, password: string, token: string): Promise<Response> => {
  const payload = { old_password: oldPassword, password };
  return fetch(`${config.apiBaseURL}/member/v2/laas/password`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(payload),
  });
};
