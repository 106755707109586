import { logger } from '@streetshares/frontend-common';

import { ApplicationApiErrorResponse, LendingApplication } from 'Types/app';

import { ApiEndpoint } from './businessEndpoints';
import { FetchOptions, getAuthHeaders } from './fetch';

export const createApplicationCreationRequest = (token: string, businessId?: number): FetchOptions => ({
  url: ApiEndpoint.applications,
  method: 'POST',
  headers: getAuthHeaders(token),
  body: JSON.stringify({ business_id: businessId }),
});

export const fetchApplicationId = async (businessId: number | undefined, token: string) => {
  const { url: applicationUrl, ...applicationOptions } = createApplicationCreationRequest(token, businessId);
  const applicationResponse = await fetch(applicationUrl, applicationOptions);
  if (!applicationResponse.ok) {
    const response: ApplicationApiErrorResponse = (await applicationResponse.json()) as ApplicationApiErrorResponse;
    logger.error('Failed to create an application.', {
      message: response,
    });

    const errorMessage = response.detail?.errors?.[0].message ?? '';
    throw new Error(`Failed to create a new application. ${errorMessage}`);
  }

  const { id: appId }: LendingApplication = (await applicationResponse.json()) as LendingApplication;

  return appId;
};
