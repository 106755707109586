import useSWR from 'swr';

import { config } from 'config';
import { Member } from 'Types/member';

type CurrentMember = {
  memberData: Member;
};
export const useCurrentMember = (): CurrentMember => {
  const { data } = useSWR<Member>(`${config.apiBaseURL}/member/v2/laas/member/current`);

  return { memberData: data ?? ({} as Member) };
};
