import { AppBar, makeStyles } from '@material-ui/core';
import { Header as CommonHeader } from '@streetshares/frontend-common';
import { FC } from 'react';

import { changePassword } from 'api/memberApi';
import { useAuth } from 'auth/useAuth';
import { useBranding } from 'branding/useBranding';
import { logoutUser } from 'auth/logout';
import { config } from 'config';

import { useSystemStatus } from './SystemStatus/useSystemStatus';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

export const Header: FC = () => {
  const { logoUrl } = useBranding();
  const classes = useStyles();
  const { user } = useAuth();
  const { status: systemStatus } = useSystemStatus();
  const { systemStatusPageLink } = config;

  const { token = '' } = user ?? {};

  const onChangePassword = async (oldPassword: string, password: string): Promise<string> => {
    const response = await changePassword(oldPassword, password, token);

    if (response.status === 401) {
      return 'Invalid password, please enter correct old password and try again';
    }

    return '';
  };

  return (
    <header>
      <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
        <CommonHeader
          logoUrl={logoUrl}
          user={{
            memberId: 123,
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email,
            auth0Roles: user?.auth0Roles,
            token,
            userId: user?.memberId,
          }}
          token={token}
          logoutUser={logoutUser}
          onChangePassword={onChangePassword}
          systemStatus={systemStatus}
          systemStatusPageLink={systemStatusPageLink}
        />
      </AppBar>
    </header>
  );
};
