import { SessionUser } from '@streetshares/frontend-common';

export const UnauthenticatedAppRoutes = {
  Signin: '/signin',
};

export const AuthenticatedAppRoutes = {
  Home: '/home',
  Business: '/:businessId(\\d+)?/business-info',
};

export const AppRoutes = { ...UnauthenticatedAppRoutes, ...AuthenticatedAppRoutes };

export const getInitialApplicationRoute = ({ user }: { user?: SessionUser }): string => {
  if (!user) {
    return UnauthenticatedAppRoutes.Signin;
  }

  return AuthenticatedAppRoutes.Home;
};
