import { SelectData } from 'mui-rff';

import { config } from 'config';

export function generateSelectOptions(options: Array<string>): Array<SelectData> {
  return [{ value: '', label: 'Select a value...', disabled: true }, ...options.map((o) => ({ value: o, label: o }))];
}

export const setMetaDescription = (metaDescription: string): null => {
  const metaDescriptionEl = document.querySelector('meta[name="description"]');

  if (metaDescriptionEl) {
    metaDescriptionEl.setAttribute('content', metaDescription);
  }
  return null;
};

export enum BLARoutes {
  HOME = 'home',
  COVENANT_REQUEST = 'covenant-documents',
}

export const redirectToBLA = (applicationId: number | undefined, sectionRoute?: string) => {
  if (!applicationId) {
    return;
  }

  const redirectUrl = `${config.businessLoanAppPublicUrl}/${applicationId}/${sectionRoute ?? BLARoutes.HOME}`;
  window.location.assign(redirectUrl);
};
