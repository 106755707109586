import { ReactElement } from 'react';
import { GridBox, GridRow, GridCol } from '@streetshares/frontend-common';
import { makeStyles, Typography } from '@material-ui/core';
import { Error } from '@material-ui/icons';

import { ApplicationStatus, ProductsToApply } from 'Types/app';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  root: {
    width: '100%',
    height: '82px',
    borderRadius: '8px',
    marginBottom: '30px',
    boxShadow: '0 2px 8px 0 rgba(0,0,0,0.3)',
    backgroundColor: '#ECEDF1',
    border: `1px solid ${palette.grey[300]}`,
    padding: '10px',
    cursor: 'default',
    [breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  title: {
    display: 'inline-block',
    [breakpoints.down('sm')]: {
      display: 'inherit',
    },
    verticalAlign: 'middle',
    marginBottom: 0,
    paddingBottom: '0!important',
    '& p': {
      marginLeft: '10px',
      transform: 'translate(9px,-6px)',
    },
  },
  icon: {
    color: palette.primary.main,
    fontSize: '30px',
    transform: 'translate(4px,5px)',
  },
  expired: {
    boxShadow: 'none',
  },
}));

type ProductProps = {
  product: string;
  icon: ReactElement;
};

type ExpiredAndDeclinedProps = {
  productToApply: ProductsToApply;
  applicationId: number | undefined;
  applicationStatus: ApplicationStatus;
};

export const ExpiredAndDeclinedCard: React.FC<ExpiredAndDeclinedProps> = ({
  productToApply,
  applicationId,
  applicationStatus,
}) => {
  const classes = useStyles();

  const products: Record<ProductsToApply, ProductProps> = {
    BLX: {
      product: 'Business Loan Application',
      icon: <Error className={classes.icon} />,
    },
    CREDIT_CARD: {
      product: 'Credit Card Application',
      icon: <Error className={classes.icon} />,
    },
  };

  const messageExpired = `Your ${products[productToApply].product} (ID ${applicationId ?? ''}) has expired`;
  const messageDeclined = `Your ${products[productToApply].product} (ID ${applicationId ?? ''}) has been declined`;

  const isExpired = applicationStatus === 'EXPIRED';

  return (
    <GridBox className={`${classes.root} ${isExpired ? classes.expired : ''}`}>
      <GridRow container>
        <GridCol className={classes.title} item md={12} lg={12}>
          {products[productToApply].icon}
          <Typography className={classes.title} variant="body2" component="p">
            {isExpired ? messageExpired : messageDeclined}
          </Typography>
        </GridCol>
      </GridRow>
    </GridBox>
  );
};
