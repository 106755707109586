import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { config } from 'config';
import { AuthRouter } from 'routes/AuthRouter';
import { Router } from 'routes/Router';
import { useAuth } from 'auth/useAuth';

export const SelectRouter: React.FC = () => {
  const { user } = useAuth();

  return <BrowserRouter basename={config.basename}>{user ? <AuthRouter /> : <Router />}</BrowserRouter>;
};
