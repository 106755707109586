import { config } from 'config';
import { FetchOptions, getAuthHeaders } from 'api/fetch';
import { ApplicationPersonRoles } from 'Types/app';

type PersonCreationValues = {
  first_name?: string;
  last_name?: string;
  email?: string;
  roles: Array<string>;
};
export const createPersonCreateRequest = (
  businessId: number | undefined,
  token: string,
  data: PersonCreationValues,
): FetchOptions => ({
  url: `${config.apiBaseURL}/business/customer/businesses/${businessId ?? ''}/persons`,
  method: 'POST',
  headers: getAuthHeaders(token),
  body: JSON.stringify(data),
});

export const createPersonRolesPatchRequest = (
  personId: number,
  applicationId: number,
  data: ApplicationPersonRoles,
  token: string,
): FetchOptions => ({
  url: `${config.apiBaseURL}/laas-application/borrower/lending/applications/${applicationId}/persons/${personId}/roles`,
  method: 'POST',
  headers: getAuthHeaders(token),
  body: JSON.stringify({ roles: data }),
});
