import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { Button, makeStyles, Box, Grid, Container } from '@material-ui/core';

// import { ErrorBackground } from 'assets/generated-svg-components';

const useStyles = makeStyles(({ palette }) => ({
  errorScreen: {
    display: 'flex',
    alignSelf: 'center',
  },
  suburbImage: {
    position: 'absolute',
    width: '100vw',
    left: 0,
    bottom: 0,
  },
  errorMessage: { backgroundColor: palette.info.light, padding: '10px' },
}));

export const ErrorFallback: React.FC<FallbackProps> = ({ error /* , componentStack, */, resetErrorBoundary }) => {
  const classes = useStyles();
  return (
    <Box role="alert" display="flex" justifyContent="center" m={4}>
      {/* <ErrorBackground className={classes.suburbImage} /> */}
      <Grid container>
        <Container maxWidth="lg">
          <Grid item>
            <p>Something went wrong:</p>
            <pre className={classes.errorMessage}>{error?.message}</pre>
            <p>Please try again, or contact your financial institution for assistance.</p>
            <Button type="button" variant="outlined" onClick={resetErrorBoundary}>
              Try again
            </Button>
          </Grid>
        </Container>
      </Grid>
    </Box>
  );
};
