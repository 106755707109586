import { Dispatch, SetStateAction } from 'react';
import { GridBox, GridRow, GridCol, PrimaryButton, TextButton } from '@streetshares/frontend-common';
import { Typography, makeStyles, Modal, Box, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { useBranding } from 'branding/useBranding';
import { AuthenticatedAppRoutes } from 'routes/AppRoutes';
import { CloseButton } from 'components/Common/CloseButton';

export const useStyles = makeStyles(({ palette }) => ({
  container: {
    height: '78vh',
  },
  headerText: {
    fontSize: '2rem',
    alignSelf: 'center',
  },
  box: {
    maxWidth: '800px',
    margin: 'auto',
    textAlign: 'left',
    paddingLeft: '50px',
    paddingBottom: '50px',
    paddingRight: '0px',
    paddingTop: '14.5px',
    boxShadow: ' 0 2px 8px 0 rgba(0,0,0,0.5)',
  },
  icon: {
    fontSize: '3rem',
    color: palette.primary.main, // fix the color
  },
  text: {
    '&:not(:last-child)': {
      paddingBottom: '8px',
    },
  },
  reasonContainer: {
    width: 'fit-content',
    paddingBottom: '8px',
    color: '#DB2426',
  },
  textButton: {
    paddingLeft: 0,
    paddingRight: 16,
  },
  primaryButton: {
    minWidth: 'unset',
  },
}));

type ModalOfIneligibilityProps = {
  isOpen: boolean | null;
  ineligibilityReasons?: string[];
  setIsBusinessEligible: Dispatch<SetStateAction<boolean | null>>;
};
export const ModalOfIneligibility: React.FC<ModalOfIneligibilityProps> = ({
  isOpen,
  ineligibilityReasons = [],
  setIsBusinessEligible,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { partnerName } = useBranding();

  return (
    <Modal open={isOpen ?? false}>
      <GridRow className={classes.container} container>
        <GridBox className={classes.box} border="0">
          <Grid container spacing={0} direction="row" justifyContent="flex-end">
            <Grid item xs={1} md={1} lg={1}>
              <CloseButton label="Go to Customer Hub." onClick={() => history.push(AuthenticatedAppRoutes.Home)} />
            </Grid>
          </Grid>
          <GridRow>
            <GridCol>
              <Typography variant="h1" className={classes.headerText}>
                Your Business is Not Eligible for Business Credit
              </Typography>
              <Box pt={4} pb={4}>
                <Typography className={classes.text} variant="body1">
                  This is based on your response for:
                </Typography>
                <ul className={classes.reasonContainer}>
                  {ineligibilityReasons.map((reason: string, i) => {
                    return (
                      <li key={`${reason}-${i}`}>
                        <Typography variant="body1">{reason}</Typography>
                      </li>
                    );
                  })}
                </ul>
                <Typography className={classes.text} variant="body1">
                  You have not submitted an application for consideration or made a request for credit to {partnerName}.
                  If you believe that your business is eligible for credit, please contact {partnerName}.
                </Typography>
              </Box>
              <TextButton className={classes.textButton} onClick={() => history.push(AuthenticatedAppRoutes.Home)}>
                Go to Customer Hub
              </TextButton>
              <PrimaryButton className={classes.primaryButton} onClick={() => setIsBusinessEligible(null)}>
                Return to Editing
              </PrimaryButton>
            </GridCol>
          </GridRow>
        </GridBox>
      </GridRow>
    </Modal>
  );
};
