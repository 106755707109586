import { FC, useState, MouseEvent } from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { TextButton } from '@streetshares/frontend-common';

import { verifyEmails } from 'api/businessApi';
import { useAuth } from 'auth/useAuth';

const useStyles = makeStyles(({ palette }) => ({
  container: {
    backgroundColor: '#BBDEFB',
    height: '30px',
    padding: '0',
    border: 'none',
    borderRadius: '6px 6px 0px 0px',
    display: 'flex',
  },
  text: {
    fontSize: '0.875rem',
    margin: '5px 0px 0px 152px',
    display: 'block',
  },
  link: {
    color: palette.common.black,
    padding: 0,
    '& span': {
      transform: 'translate(3px, -2px)',
      fontFamily: 'Roboto',
      fontSize: '0.875rem',
      fontWeight: 500,
      minWidth: '0',
      padding: 0,
    },
  },
}));

type EmailVerificationBoxProps = {
  businessId: number | undefined;
};

enum RenderMessageKeys {
  UNVERIFIED = 'UNVERIFIED',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export const EmailVerificationBox: FC<EmailVerificationBoxProps> = ({ businessId }) => {
  const { user: { token = '' } = {} } = useAuth();
  const [message, setMessage] = useState<RenderMessageKeys>(RenderMessageKeys.UNVERIFIED);
  const classes = useStyles();

  const handleVerifyEmails = async (
    e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent> | MouseEvent<HTMLSpanElement, globalThis.MouseEvent>,
  ) => {
    e.stopPropagation();
    const response = await verifyEmails(businessId, token, '?force_resend=true');
    if (response) {
      setMessage(RenderMessageKeys.SUCCESS);
    } else {
      setMessage(RenderMessageKeys.ERROR);
    }
  };

  const renderMessage: Record<RenderMessageKeys, JSX.Element | string> = {
    UNVERIFIED: (
      <>
        Guarantor(s) email address not verified.{' '}
        <>
          To resend verification
          <TextButton className={classes.link} onClick={(e) => handleVerifyEmails(e)}>
            click here.
          </TextButton>
        </>
      </>
    ),
    SUCCESS: 'Verification email(s) have been sent.',
    ERROR: 'An error occurred while sending verification email(s), please try again later.',
  };

  return (
    <Box className={classes.container}>
      <Typography component="span" variant="body1" className={classes.text}>
        {renderMessage[message]}
      </Typography>
    </Box>
  );
};
