import { useState } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import { Loader, TextButton } from '@streetshares/frontend-common';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { handleApplyProduct, useBusiness } from 'api/businessApi';
import { useCurrentMember } from 'api/useCurrentMember';
import { useAuth } from 'auth/useAuth';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    height: '45px',
  },
  link: {
    display: 'flex',
    color: '#2087F4',
    '&:hover': {
      color: '#1244AF',
    },
    '&:disabled': {
      color: '#C4C4C4',
    },
  },
  linkText: {
    fontWeight: 400,
    fontFamily: 'Roboto',
    marginLeft: 5,
  },
  loader: {
    padding: '0 0 0 15px',
  },
}));

type ProductLinkProps = {
  businessId?: number;
};

export const ProductLink: React.FC<ProductLinkProps> = ({ businessId }) => {
  const classes = useStyles();
  const { user: { token = '' } = {} } = useAuth();
  const { memberData } = useCurrentMember();
  const { business } = useBusiness(businessId);
  const { enableGuarantorDataPreFilling }: { enableGuarantorDataPreFilling: boolean } = useFlags();
  const [errorMessage, setErrorMessage] = useState('');
  const [disabledApplyButton, setDisabledApplyButton] = useState(false);

  return (
    <>
      <Box className={classes.container}>
        <TextButton
          className={classes.link}
          disabled={disabledApplyButton}
          onClick={async () => {
            setDisabledApplyButton(true);
            setErrorMessage('');

            try {
              await handleApplyProduct(business, memberData, token, enableGuarantorDataPreFilling);
            } catch (ex) {
              if (ex instanceof Error) {
                setErrorMessage(ex.message);
                setDisabledApplyButton(false);
              }
            }
          }}
        >
          <AddCircleRoundedIcon />
          <Typography variant="body2" className={classes.linkText}>
            Apply for Business Credit
          </Typography>
        </TextButton>

        {disabledApplyButton && <Loader message="" className={classes.loader} />}
      </Box>
      {errorMessage && (
        <Box pl={4}>
          <Typography variant="body2">{errorMessage}</Typography>
        </Box>
      )}
    </>
  );
};
