import { clearCredentials, loadCredentials } from 'auth/persister';
import { config } from 'config';

export function clearSession(): void {
  const credentials = loadCredentials();
  if (!credentials) {
    return;
  }

  clearCredentials();
}

export function logoutUser(): void {
  try {
    clearSession();
  } catch (e) {
    return;
  } finally {
    window.location.assign(config.basename);
  }
}
