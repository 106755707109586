import { statesOfUS } from '@streetshares/frontend-common';
import { SelectData } from 'mui-rff';

import {
  BusinessEntityTypes,
  TinKeys,
  TinTypes,
  BusinessAddressOwnership,
  BusinessAddressOwnershipKeys,
  EmployeeCountType,
  BusinessAgeTypes,
  BusinessAgeTypeKeys,
} from 'Types/enums';

export const businessNameProperty = 'name';
export const businessDBAProperty = 'doing_business_as';
export const businessAddressOwnershipProperty = 'address_ownership';
export const businessAddress1Property = 'address1';
export const businessAddress2Property = 'address2';
export const businessManualEntryProperty = 'is_manually_entered_address';
export const businessCityProperty = 'city';
export const businessStateProperty = 'state';
export const businessZipcodeProperty = 'zipcode';
export const businessPhoneProperty = 'phone';
export const businessWebsiteProperty = 'website';
export const businessIndustryProperty = 'naics_code';
export const businessEntityTypeProperty = 'entity_type';
export const businessStateOfFormationProperty = 'formation_state';
export const businessIsSeasonalProperty = 'is_seasonal';
export const businessEmployeeCountProperty = 'number_of_employees';
export const businessTinProperty = 'tin';
export const businessAnnualRevenueProperty = 'annual_revenue';
export const businessTinTypeProperty = 'tin_type';
export const businessAgeProperty = 'age';

export const employeeCountOptions: Array<SelectData> = [
  { value: '', label: 'Select a value...', disabled: true },
  { value: 0, label: EmployeeCountType.ZERO },
  { value: 1, label: EmployeeCountType.ONE },
  { value: 3, label: EmployeeCountType.THREE },
  { value: 6, label: EmployeeCountType.SIX },
  { value: 11, label: EmployeeCountType.ELEVEN },
  { value: 21, label: EmployeeCountType.TWENTYONE },
  { value: 50, label: EmployeeCountType.FIFTY },
];

export const stateOfFormationOptions = [{ value: '', label: 'Select a value...', disabled: true }].concat(
  Object.entries(statesOfUS).map(([key, val]) => ({ value: key, label: val, disabled: false })),
);

export const businessEntityTypeOptions: Array<SelectData> = [
  { value: '', label: 'Select a value...', disabled: true },
].concat(Object.entries(BusinessEntityTypes).map(([key, val]) => ({ value: key, label: val, disabled: false })));

export const businessAddressOwnershipOptions: Array<SelectData> = [
  { value: '', label: 'Select a value...', disabled: true },
  {
    value: BusinessAddressOwnershipKeys.OWNED,
    label: BusinessAddressOwnership.OWNED,
  },
  { value: BusinessAddressOwnershipKeys.LEASED, label: BusinessAddressOwnership.LEASED },
];

export enum BusinessInfoLabels {
  BUSINESS_NAME = 'Business Name',
  BUSINESS_DBA = 'DBA',
  BUSINESS_ADDRESS1 = 'Business Address',
  BUSINESS_PHONE = 'Business Phone Number',
  BUSINESS_WEBSITE = 'Business Website',
  NAICS_CODE = 'Industry / NAICS Code',
  BUSINESS_ENTITY_TYPE = 'Business Type',
  BUSINESS_EMPLOYEE_COUNT = '# of Employees',
  BUSINESS_TIN = 'Identification Number',
  BUSINESS_SSN = 'Social Security Number',
  BUSINESS_TIN_TYPE = 'Identification Type',
  BUSINESS_IS_SEASONAL = 'is this business seasonal?',
  BUSINESS_ANNUAL_REVENUE = 'Annual Revenue',
  FORMATION_STATE = 'State of Formation',
  BUSINESS_AGE = 'Business Age',
}

export const BusinessInfoPathToName: Record<string, string> = {
  name: 'Business Name',
  doing_business_as: 'DBA',
  address1: 'Business Address',
  address_ownership: 'Business Type of Location',
  phone: 'Business Phone Number',
  naics_code: 'Business Industry',
  tin_type: 'Identity Type',
  tin: 'Identity Number',
  website: 'Business Website',
  entity_type: 'Business Type',
  formation_state: 'State of Formation',
  age: 'Business Age',
  number_of_employees: '# of Employees',
  annual_revenue: 'Annual Revenue',
};

export const tinTypeOptions: Array<SelectData> = [
  { value: '', label: 'Select an identification type...', disabled: true },
  { value: TinKeys.SSN, label: TinTypes.SSN },
  { value: TinKeys.ITIN, label: TinTypes.ITIN },
  { value: TinKeys.EIN, label: TinTypes.EIN },
];

export const businessAgeOptions: Array<SelectData> = [
  { value: '', label: 'Select a value...', disabled: true },
  {
    value: BusinessAgeTypeKeys.UNDER_ONE_YEAR,
    label: BusinessAgeTypes[BusinessAgeTypeKeys.UNDER_ONE_YEAR],
  },
  {
    value: BusinessAgeTypeKeys.FROM_ONE_TO_TWO_YEARS,
    label: BusinessAgeTypes[BusinessAgeTypeKeys.FROM_ONE_TO_TWO_YEARS],
  },
  {
    value: BusinessAgeTypeKeys.TWO_YEARS,
    label: BusinessAgeTypes[BusinessAgeTypeKeys.TWO_YEARS],
  },
  {
    value: BusinessAgeTypeKeys.THREE_YEARS,
    label: BusinessAgeTypes[BusinessAgeTypeKeys.THREE_YEARS],
  },
  {
    value: BusinessAgeTypeKeys.FOUR_YEARS,
    label: BusinessAgeTypes[BusinessAgeTypeKeys.FOUR_YEARS],
  },
  {
    value: BusinessAgeTypeKeys.FIVE_YEARS,
    label: BusinessAgeTypes[BusinessAgeTypeKeys.FIVE_YEARS],
  },
  {
    value: BusinessAgeTypeKeys.SIX_YEARS,
    label: BusinessAgeTypes[BusinessAgeTypeKeys.SIX_YEARS],
  },
  {
    value: BusinessAgeTypeKeys.SEVEN_YEARS,
    label: BusinessAgeTypes[BusinessAgeTypeKeys.SEVEN_YEARS],
  },
  {
    value: BusinessAgeTypeKeys.EIGHT_YEARS,
    label: BusinessAgeTypes[BusinessAgeTypeKeys.EIGHT_YEARS],
  },
  {
    value: BusinessAgeTypeKeys.NINE_YEARS,
    label: BusinessAgeTypes[BusinessAgeTypeKeys.NINE_YEARS],
  },
  {
    value: BusinessAgeTypeKeys.TEN_YEARS,
    label: BusinessAgeTypes[BusinessAgeTypeKeys.TEN_YEARS],
  },
  {
    value: BusinessAgeTypeKeys.OVER_TEN_YEARS,
    label: BusinessAgeTypes[BusinessAgeTypeKeys.OVER_TEN_YEARS],
  },
];
