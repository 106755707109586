import { GridBox, GridRow, GridCol, PrimaryButton, TextButton } from '@streetshares/frontend-common';
import { Typography, makeStyles, Modal, Box, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { AuthenticatedAppRoutes } from 'routes/AppRoutes';
import { handleApplyProduct, useBusiness } from 'api/businessApi';
import { useCurrentMember } from 'api/useCurrentMember';
import { useAuth } from 'auth/useAuth';
import { CloseButton } from 'components/Common/CloseButton';

export const useStyles = makeStyles(({ palette }) => ({
  container: {
    height: '78vh',
  },
  headerText: {
    fontSize: '2.125rem',
    alignSelf: 'center',
  },
  box: {
    width: '800px',
    maxHeight: '282px',
    margin: 'auto',
    textAlign: 'left',
    paddingLeft: '50px',
    paddingBottom: '50px',
    paddingRight: '0px',
    paddingTop: '14.5px',
    boxShadow: ' 0 2px 8px 0 rgba(0,0,0,0.5)',
  },
  icon: {
    fontSize: '3rem',
    color: palette.primary.main, // fix the color
  },
  text: {
    '&:not(:last-child)': {
      paddingBottom: '8px',
    },
  },
  reasonContainer: {
    width: 'fit-content',
    paddingBottom: '8px',
    color: '#DB2426',
  },
  textButton: {
    '& span': {
      paddingLeft: 0,
      paddingRight: 16,
      fontFamily: 'Roboto',
      fontWeight: 500,
    },
  },
  primaryButton: {
    '& span': {
      minWidth: 'unset',
      fontFamily: 'Roboto',
      fontWeight: 500,
    },
  },
}));

type ModalOfEligibilityProps = {
  isOpen: boolean | null;
  businessId: string;
};

export const ModalOfEligibility: React.FC<ModalOfEligibilityProps> = ({ isOpen, businessId }) => {
  const classes = useStyles();
  const history = useHistory();
  const { user: { token = '' } = {} } = useAuth();
  const { business } = useBusiness(parseInt(businessId, 10));
  const { memberData } = useCurrentMember();
  const { enableGuarantorDataPreFilling }: { enableGuarantorDataPreFilling: boolean } = useFlags();

  return (
    <Modal open={isOpen ?? false}>
      <GridRow className={classes.container} container>
        <GridBox className={classes.box} border="0">
          <Grid container spacing={0} direction="row" justifyContent="flex-end">
            <Grid item xs={1} md={1} lg={1}>
              <CloseButton label="Go to Customer Hub." onClick={() => history.push(AuthenticatedAppRoutes.Home)} />
            </Grid>
          </Grid>
          <GridRow>
            <GridCol>
              <Typography variant="h1" className={classes.headerText}>
                Your Business is Eligible for Business Credit
              </Typography>
              <Box pt={4} pb={4}>
                <Typography className={classes.text} variant="body1">
                  Would you like to start an application now?
                </Typography>
              </Box>
              <TextButton className={classes.textButton} onClick={() => history.push(AuthenticatedAppRoutes.Home)}>
                Go to Customer Hub
              </TextButton>
              <PrimaryButton
                className={classes.primaryButton}
                onClick={() => {
                  handleApplyProduct(business, memberData, token, enableGuarantorDataPreFilling).finally(() => {});
                }}
              >
                Yes, Start Application
              </PrimaryButton>
            </GridCol>
          </GridRow>
        </GridBox>
      </GridRow>
    </Modal>
  );
};
