import { omit } from 'lodash';
import useSWR from 'swr';
import { logger } from '@streetshares/frontend-common';

import { LendingApplication, LendingUser, Business, BusinessPerson, LendingApplicationForUpdate } from 'Types/app';
import { Member } from 'Types/member';
import { UserRole } from 'Types/enums';
import { createPersonCreateRequest, createPersonRolesPatchRequest } from 'api/personApi';
import { redirectToBLA } from 'utils/utils';
import { ApiEndpoint } from 'api/businessEndpoints';
import { fetchApplicationId } from 'api/applicationApi';
import { FetchOptions, getAuthHeaders } from 'api/fetch';

// Map of API endpoints to response types
export type ApiEndpointResponse = {
  application: LendingApplication;
  applications: Array<LendingApplication>;
  business: Business;
  user: LendingUser;
};

export const createPatchRequest = (
  data: Partial<LendingApplication | Business | LendingUser>,
  token: string,
): RequestInit => ({
  method: 'PATCH',
  headers: getAuthHeaders(token),
  body: JSON.stringify(data),
});

export const createBusinessCreationRequest = (initialBusiness: Business, token: string): FetchOptions => ({
  url: ApiEndpoint.applications,
  method: 'POST',
  headers: getAuthHeaders(token),
  body: JSON.stringify(initialBusiness),
});

export const createSplitPatchRequest = (key: string, data: string | boolean | number, token: string): RequestInit => ({
  method: 'PATCH',
  headers: getAuthHeaders(token),
  body: JSON.stringify({ [key]: data }),
});

export const createBusiness = (token: string, business: Business): FetchOptions => ({
  url: `${ApiEndpoint.business}`,
  method: 'POST',
  headers: getAuthHeaders(token),
  body: JSON.stringify(business),
});

export const getAllBusinesses = (token: string): FetchOptions => ({
  url: `${ApiEndpoint.business}`,
  method: 'GET',
  headers: getAuthHeaders(token),
});

export const createApplicationBusinessIdRequest = (
  appId: number | undefined,
  data: Partial<LendingApplication>,
  token: string,
): FetchOptions => ({
  url: `${ApiEndpoint.applications}/${appId ?? ''}/business`,
  method: 'POST',
  headers: getAuthHeaders(token),
  body: JSON.stringify(data),
});

export const createApplicationPatchRequest = (
  applicationId: number,
  data: LendingApplicationForUpdate,
  token: string,
): FetchOptions => ({
  url: `${ApiEndpoint.applications}/${applicationId}`,
  method: 'PATCH',
  headers: getAuthHeaders(token),
  body: JSON.stringify(data),
});

export const businessPutRequest = (data: Business, token: string): FetchOptions => ({
  url: `${ApiEndpoint.business}/${data.id ?? ''}`,
  method: 'PUT',
  headers: getAuthHeaders(token),
  body: JSON.stringify(
    omit(data, [
      'id',
      'organization_id',
      'is_eligible',
      'eligibility_checks',
      'persons',
      'business_age',
      'is_complete',
      'version',
    ]),
  ),
});

export const verifyEmailsRequest = (
  businessId: number | undefined,
  token: string,
  queryParams?: string,
): FetchOptions => ({
  url: `${ApiEndpoint.business}/${businessId ?? ''}/calculate-roles${queryParams || ''}`,
  method: 'POST',
  headers: getAuthHeaders(token),
  body: JSON.stringify({}),
});

export const verifyEmails = async (
  businessId: number | undefined,
  token: string,
  queryParams?: string,
): Promise<boolean> => {
  const { url, ...options } = verifyEmailsRequest(businessId, token, queryParams);
  const response = await fetch(url, options);
  if (!response.ok) {
    logger.error('Guarantors emails could not be verified', {
      message: response.json(),
      ...{ businessId },
    });
    return false;
  }
  return true;
};

type BusinessResponse = {
  business: Business;
};
export const useBusiness = (businessId: number | undefined): BusinessResponse => {
  const { data } = useSWR<Business>(businessId ? `${ApiEndpoint.business}/${businessId}` : null);

  return { business: data ?? ({} as Business) };
};

export const handleApplyProduct = async (
  business: Business,
  memberData: Member,
  token: string,
  enableGuarantorDataPreFilling: boolean,
) => {
  const appId = await fetchApplicationId(business.id, token);

  const { url, ...options } = createApplicationBusinessIdRequest(appId, { business_id: business.id }, token);
  const response = await fetch(url, options);

  if (!response.ok) {
    logger.error('Failed to create application for business.', {
      message: await response.json(),
    });
    throw new Error('Failed to create application for business.');
  }

  if (!enableGuarantorDataPreFilling) {
    redirectToBLA(appId);
    return;
  }

  if (business.persons.length === 0) {
    const createPersonReq = createPersonCreateRequest(business.id, token, {
      first_name: memberData.first_name,
      last_name: memberData.last_name,
      email: memberData.email,
      roles: [UserRole.BENEFICIAL_OWNER],
    });

    const ownerResp = await fetch(createPersonReq.url, createPersonReq);
    if (!ownerResp.ok) {
      logger.error('Failed to create a person', {
        message: await ownerResp.json(),
      });
    } else {
      const person: BusinessPerson = (await ownerResp.json()) as BusinessPerson;

      const updatePersonRoleReq = createPersonRolesPatchRequest(person.id, appId, [UserRole.AUTH_REP], token);
      const updatePersonResp = await fetch(updatePersonRoleReq.url, updatePersonRoleReq);
      if (!updatePersonResp.ok) {
        logger.error('Failed to update person', {
          message: await updatePersonResp.json(),
        });
      }
    }
  } else {
    const guarantorId = business.persons[0].id;
    const updatePersonRoleReq = createPersonRolesPatchRequest(guarantorId, appId, [UserRole.AUTH_REP], token);
    const guarantorResp = await fetch(updatePersonRoleReq.url, updatePersonRoleReq);
    if (!guarantorResp.ok) {
      logger.error('Failed to update person', {
        message: await guarantorResp.json(),
      });
    }
  }

  redirectToBLA(appId);
};
