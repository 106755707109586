import { external } from '../../generated/laas-application-schema';

type Components = external['../../../common3/common3/swagger/enums/state.yml'];

type USState = Components['components']['schemas']['UsState'];

export enum LendingProductType {
  TERM_LOAN = 'TERM_LOAN',
  DRAW = 'DRAW',
  LOC = 'LOC',
}

export const BusinessEntityTypes: Record<string, string> = {
  SOLE_PROPRIETOR: 'Sole Proprietor',
  GENERAL_PARTNERSHIP: 'General Partnership',
  LIMITED_PARTNERSHIP: 'Limited Partnership',
  LIMITED_LIABILITY_PARTNERSHIP: 'Limited Liability Partnership',
  C_CORP: 'C-Corp',
  S_CORP: 'S-Corp',
  LLC: 'LLC',
  _501_C_3_NONPROFIT: '501(c)(3) Nonprofit',
  OTHER_501_C_ORGANIZATION: 'Other 501(c) Organization',
  COOPERATIVE: 'Cooperative',
  ESOP: 'ESOP',
  TRIBAL_BUSINESS: 'Tribal Business',
  GOVERNMENT_ENTITY: 'Government Entity',
  OTHER: 'Other',
};

export enum BusinessAddressOwnership {
  OWNED = 'Owned',
  LEASED = 'Leased',
}

export enum BusinessAddressOwnershipKeys {
  OWNED = 'OWNED',
  LEASED = 'LEASED',
}

export enum IdentificationTypes {
  SSN = 'Social Security Number',
  PASSPORT = 'Passport',
  ALIEN_ID = 'Alien Identification Card',
  OTHER = 'Other',
}

export enum IdentificationKeys {
  SSN = 'SSN',
  PASSPORT = 'PASSPORT',
  ALIEN_ID = 'ALIEN_ID',
  OTHER = 'OTHER',
}

export enum EmployeeCountType {
  ZERO = '0 Employees',
  ONE = '1-2 Employees',
  THREE = '3-5 Employees',
  SIX = '6-10 Employees',
  ELEVEN = '11-20 Employees',
  TWENTYONE = '21-50 Employees',
  FIFTY = '> 50 Employees',
}

export enum ApplicationSteps {
  ELIGIBILITY_CHECK = 'ELIGIBILITY_CHECK',
  BUSINESS_INFO = 'BUSINESS_INFO',
  LOAN_REQUEST = 'LOAN_REQUEST',
  APPLICANT_AND_BENEFICIAL_OWNERS = 'GUARANTORS_AND_BENEFICIAL_OWNERS',
  BUSINESS_FINANCES_AND_DOCUMENTS = 'FINANCIAL_INFO_AND_DOCS',
  COLLATERAL = 'COLLATERAL',
  CERTIFICATIONS_TERMS_AND_AGREEMENTS = 'CERTIFICATIONS_TERMS_AND_AGREEMENTS',
}

export const States: Array<USState> = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
  'AS',
  'GU',
  'MP',
  'PR',
  'UM',
  'VI',
];

export enum TinTypes {
  SSN = 'Social Security Number',
  ITIN = 'Individual Taxpayer Identification Number',
  EIN = 'Employer Identification Number',
}

export enum TinKeys {
  SSN = 'SSN',
  ITIN = 'ITIN',
  EIN = 'EIN',
}

export enum TypesOfLoan {
  TERM_LOAN = 'Term Loan',
  LOC = 'Line of Credit',
  SECURED_LOAN = 'Secured Loan',
  SECURED_LOC = 'Secured Line of Credit',
}

export enum TypesOfLoanKeys {
  TERM_LOAN = 'TERM_LOAN',
  LOC = 'LOC',
  SECURED_LOAN = 'SECURED_LOAN',
  SECURED_LOC = 'SECURED_LOC',
}

export enum ProductToApplyKeys {
  BLX = 'BLX',
  CREDIT_CARD = 'CREDIT_CARD',
}

export enum ProductSettingsBLX {
  SUPPORT_EMAIL = 'atlas:config:BLX:contacts:support_email',
  SUPPORT_PHONE = 'atlas:config:BLX:contacts:support_phone',
  WITH_CUSTOMER_UX = 'atlas:config:BLX:Closing:with_customer_ux',
}

export enum ProductTypes {
  BLX = 'BLX',
}

export enum UserRole {
  GUARANTOR_AND_PRIMARY_CONTACT = 'GUARANTOR_AND_PRIMARY_CONTACT',
  AUTH_REP = 'AUTH_REP',
  BENEFICIAL_OWNER = 'BENEFICIAL_OWNER',
}

export enum BusinessAgeTypeKeys {
  UNDER_ONE_YEAR = 'UNDER_ONE_YEAR',
  FROM_ONE_TO_TWO_YEARS = 'FROM_ONE_TO_TWO_YEARS',
  TWO_YEARS = 'TWO_YEARS',
  THREE_YEARS = 'THREE_YEARS',
  FOUR_YEARS = 'FOUR_YEARS',
  FIVE_YEARS = 'FIVE_YEARS',
  SIX_YEARS = 'SIX_YEARS',
  SEVEN_YEARS = 'SEVEN_YEARS',
  EIGHT_YEARS = 'EIGHT_YEARS',
  NINE_YEARS = 'NINE_YEARS',
  TEN_YEARS = 'TEN_YEARS',
  OVER_TEN_YEARS = 'OVER_TEN_YEARS',
}

export const BusinessAgeTypes: Record<BusinessAgeTypeKeys, string> = {
  UNDER_ONE_YEAR: 'Under 1 Year',
  FROM_ONE_TO_TWO_YEARS: '1 Year to 2 Years',
  TWO_YEARS: '2 Years',
  THREE_YEARS: '3 Years',
  FOUR_YEARS: '4 Years',
  FIVE_YEARS: '5 Years',
  SIX_YEARS: '6 Years',
  SEVEN_YEARS: '7 Years',
  EIGHT_YEARS: '8 Years',
  NINE_YEARS: '9 Years',
  TEN_YEARS: '10 Years',
  OVER_TEN_YEARS: 'Over 10 Years',
};
