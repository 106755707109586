import { config } from 'config';

export const apiUrl = `${config.apiBaseURL}/laas-application/borrower/lending`;
export const businessUrl = `${config.apiBaseURL}/business/customer/businesses`;
export const listBusinessUrl = `${config.apiBaseURL}/business/hub/businesses`;
export const listApplicationsUrl = `${config.apiBaseURL}/laas-application/hub/applications`;

// Map of named API endpoints to routes
export const ApiEndpoint = {
  applications: `${apiUrl}/applications`,
  business: businessUrl,
  listBusiness: listBusinessUrl,
  listApplications: listApplicationsUrl,
};
