import { createContext } from 'react';
import { SessionUser } from '@streetshares/frontend-common';

export type AuthContextProps = {
  organizationId: number;
  user: SessionUser & { appId?: number };
  error: Error | null;
  loginUser: (user: SessionUser, appId: number | undefined) => void;
  logoutUser: () => void;
};

export const AuthContext = createContext<Partial<AuthContextProps>>({} as AuthContextProps);
