import { Box, makeStyles } from '@material-ui/core';

import { ApplicationStatus, HubBusinessApplication, HubBusinessApplicationsGroup } from 'Types/app';
import { useProductConfig } from 'api/productConfig';
import { ProductSettingsBLX, ProductTypes } from 'Types/enums';

import { OfferReadyCard } from './OfferReadyCard';
import { ProductCardReady } from './ProductCardReady';
import { ExpiredAndDeclinedCard } from './ExpiredAndDeclinedCard';
import { ProductLink } from './ProductLink';

const useStyles = makeStyles({
  productCardContainer: {
    gap: '30px',
    display: 'flex',
  },
});

type ProductsSectionProps = {
  businessId?: number;
  products?: string[];
  businessApplicationGroup?: HubBusinessApplicationsGroup;
};

const compareApplications = (appA: HubBusinessApplication, appB: HubBusinessApplication) => {
  if (appB?.id && (!appA?.id || appA?.id < appB?.id)) {
    return 1;
  }
  if (appA?.id && (!appB.id || appA?.id > appB?.id)) {
    return -1;
  }

  return 0;
};

export const ProductsSection: React.FC<ProductsSectionProps> = ({ businessId, products, businessApplicationGroup }) => {
  const classes = useStyles();
  const {
    productConfig: { settings },
  } = useProductConfig(ProductTypes.BLX);
  const isExpiredAndDeclinedCard = (status: ApplicationStatus): boolean => {
    return ['EXPIRED', 'DECLINED'].includes(status);
  };

  return (
    <>
      {businessApplicationGroup &&
        businessApplicationGroup.applications?.sort(compareApplications).map((application) => (
          <Box key={application.id} className={classes.productCardContainer}>
            {application.offer && (
              <OfferReadyCard
                key={application.id}
                appId={application.id}
                offer={application.offer}
                hasActiveCovenantDocumentRequests={application.has_active_covenant_document_requests}
                applicationStatus={application.status}
                productToApply="BLX"
                withCustomerUX={settings[ProductSettingsBLX.WITH_CUSTOMER_UX]}
              />
            )}

            {!application.offer && products && application.status && !isExpiredAndDeclinedCard(application.status) && (
              <ProductCardReady
                key={application.id}
                application={application}
                businessId={businessId}
                productToApply="BLX"
              />
            )}

            {products && application.status && isExpiredAndDeclinedCard(application.status) && (
              <ExpiredAndDeclinedCard
                key={application.id}
                productToApply="BLX"
                applicationId={application.id}
                applicationStatus={application.status}
              />
            )}
          </Box>
        ))}
      <ProductLink businessId={businessId} />
    </>
  );
};
