import {
  GridRow,
  GridCol,
  GridBox,
  PasswordResetModal,
  PrimaryButton,
  PasswordField,
  emailValidator,
} from '@streetshares/frontend-common';
import React, { useReducer, ReactNode, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import createFocusOnErrorDecorator from 'final-form-focus';
import { makeStyles, Box, Typography, Link } from '@material-ui/core';
import { TextField, makeValidate, showErrorOnBlur } from 'mui-rff';
import * as Yup from 'yup';
import cx from 'classnames';

import { config } from 'config';
import { requiredMessage } from 'constants/validationMessages';
import { AuthStep } from 'components/Common/AuthStep';
import { associatePhoneNumber, loginMFA, loginWithMFA, resetPassword, challengeSMS } from 'api/apiAuth';
import { useAuth } from 'auth/useAuth';
import { asyncReducer, INITIAL_STATE } from 'reducers/async';
import { emailProperty, LoginLabels, passwordProperty } from 'constants/formFields/loginFormFields';
import { useBranding } from 'branding/useBranding';
import { LendingApplication } from 'Types/app';
import { AssociatePhoneNumberResponse, MemberApiErrorResponse, MFAError, MFAMemberInfo } from 'Types/member';
import { getAuthHeaders } from 'api/fetch';
import { ApiEndpoint } from 'api/businessEndpoints';
import { SessionUser } from 'Types/user';

import { PhoneAuthorizationModal } from '../../components/Common/PhoneAuthorizationModal';
import { ChallengeModal } from '../../components/Common/ChallengeModal';
import { RecoveryCodeModal } from '../../components/Common/RecoveryCodeModal';

const useStyles = makeStyles(({ breakpoints }) => ({
  agreementText: {
    [breakpoints.down('sm')]: {
      textAlign: 'center',
      marginTop: '1rem',
      marginBottom: 0,
    },
    marginTop: '4rem',
    marginBottom: '6rem',
  },
  pageTitle: {
    padding: '1rem 0',

    [breakpoints.down('sm')]: {
      padding: '1rem 1rem 0',
      textAlign: 'center',
    },
  },
  pageDescription: {
    padding: '0rem 0 2rem 0',
  },
  gridRow: {
    marginBottom: '2rem',
  },
  emailField: {
    marginTop: '1rem',
    maxHeight: '5rem',
  },
  resetLink: {
    textDecoration: 'underline',
  },
}));

type FormValues = {
  [emailProperty]: string;
  [passwordProperty]: string;
  emailForReset?: string;
};

type AllApplicationsResponse = {
  applications: Array<LendingApplication>;
};

const schema = Yup.object({
  [emailProperty]: Yup.string().required(requiredMessage),
  [passwordProperty]: Yup.string().required(requiredMessage),
});

const validate = makeValidate(schema);

const focusOnErrorsDecorator = createFocusOnErrorDecorator<FormValues>();

export const LoginForm: React.FC = () => {
  const { loginUser, organizationId } = useAuth();
  const [{ error, loading }, dispatch] = useReducer(asyncReducer, INITIAL_STATE);
  const classes = useStyles();
  const [resettingPassword, setResettingPassword] = useState(false);
  const { partnerName, logoUrl } = useBranding();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [memberId, setMemberId] = useState<number | null>(null);
  const [recoveryCode, setRecoveryCode] = useState<string[]>([]);
  const [comesFromEnrollment, setComesFromEnrollment] = useState(false);
  const [MFAcode, setMFACode] = useState('');

  const [enrollmentMode, setEnrollmentMode] = useState(false);
  const [recoveryMode, setRecoveryMode] = useState(false);
  const [challengeMode, setChallengeMode] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [isMFACallLoading, setIsMFACallLoading] = useState(false);
  const [checkedRecoveryTerm, setCheckedRecoveryTerms] = useState(false);

  const GENERIC_ERROR_MESSAGE = 'An error has occured. Please try again.';

  const getLoginInformation = async (userData: MFAMemberInfo) => {
    dispatch({ type: 'FETCH_INIT' });
    try {
      const allAppsResponse = await fetch(`${ApiEndpoint.applications}`, {
        headers: getAuthHeaders(userData.token),
      });

      const { applications }: AllApplicationsResponse = (await allAppsResponse.json()) as AllApplicationsResponse;

      // TODO: This will be replaced by proper app selection after the pilot, but for now we assume they only have 1 app
      const appId = applications?.[0]?.id;

      loginUser?.(
        {
          email: userData?.member?.email,
          firstName: userData?.member?.first_name,
          lastName: userData?.member?.last_name,
          token: userData?.token,
          memberId: userData?.member?.id,
          userId: userData?.member?.user_id,
          auth0Roles: userData?.member?.roles,
        } as SessionUser,
        appId,
      );
    } catch (e) {
      if (e instanceof Error) window.console.error('Login failure: ', e.message);
      dispatch({
        type: 'FETCH_FAILURE',
        error: new Error('Sign in failed. Verify your email and password and try again.'),
      });
    }
  };

  const onCloseMFAModal = () => {
    setPhoneNumber('');
    setMemberId(null);
    setComesFromEnrollment(false);
    setMFACode('');
    setEnrollmentMode(false);
    setRecoveryMode(false);
    setChallengeMode(false);
    setErrorMessage('');
    setIsMFACallLoading(false);
    setCheckedRecoveryTerms(false);
    dispatch({
      type: 'FETCH_FAILURE',
      error: new Error(''),
    });
  };

  return (
    <>
      <PhoneAuthorizationModal
        setPhoneNumber={setPhoneNumber}
        open={enrollmentMode}
        handleContinue={() => {
          const verificationPhone = `+1${phoneNumber.replace(/[_-]/g, '')}`;

          const phoneIsValid = verificationPhone.length === 12;

          setIsMFACallLoading(true);
          if (phoneIsValid) {
            associatePhoneNumber({
              phone_number: `+1${phoneNumber.replaceAll('-', '')}`,
              oob_channels: ['sms'],
              authenticator_types: ['oob'],
              member_id: memberId,
            })
              .then((r) => {
                if (r?.status >= 400) {
                  setErrorMessage(GENERIC_ERROR_MESSAGE);
                }
                return r.json();
              })
              .then((res: AssociatePhoneNumberResponse) => {
                if (res?.recovery_codes) {
                  setRecoveryCode(res?.recovery_codes);
                  setComesFromEnrollment(true);
                  setEnrollmentMode(false);
                  setRecoveryMode(true);
                  setErrorMessage('');
                }
              })
              .catch(() => setErrorMessage(GENERIC_ERROR_MESSAGE))
              .finally(() => setIsMFACallLoading(false));
          } else {
            setErrorMessage('Phone number is invalid.');
            setIsMFACallLoading(false);
          }
        }}
        onClose={onCloseMFAModal}
        logoUrl={logoUrl}
        isLoading={isMFACallLoading}
        errorMessage={errorMessage}
      />
      <RecoveryCodeModal
        open={recoveryMode}
        handleContinue={() => {
          if (!checkedRecoveryTerm) {
            setErrorMessage('You must check the box before continuing');
            return;
          }

          setErrorMessage('');
          setComesFromEnrollment(true);
          setRecoveryMode(false);
          setChallengeMode(true);
        }}
        onClose={onCloseMFAModal}
        logoUrl={logoUrl}
        recoveryCode={recoveryCode?.[0]}
        checked={checkedRecoveryTerm}
        setChecked={setCheckedRecoveryTerms}
        errorMessage={errorMessage}
      />
      {challengeMode && (
        <ChallengeModal
          logoUrl={logoUrl}
          comesFromEnrollment={comesFromEnrollment}
          maskedPhoneNumber={phoneNumber}
          open={challengeMode}
          onClose={onCloseMFAModal}
          handleContinue={() => {
            setIsMFACallLoading(true);
            loginWithMFA({ challenge_code: MFAcode.replaceAll('-', ''), member_id: memberId })
              .then(async (response) => {
                switch (response?.status) {
                  case 200: {
                    const responseData: MFAMemberInfo = (await response.json()) as MFAMemberInfo;
                    if (responseData?.token) {
                      setErrorMessage('');
                      await getLoginInformation(responseData);
                    }
                    break;
                  }
                  case 403: {
                    const responseData: MFAError = (await response.json()) as MFAError;
                    if (responseData?.error === 'expired_token') {
                      onCloseMFAModal();
                      dispatch({
                        type: 'FETCH_FAILURE',
                        error: new Error('Your session has expired. Please try again.'),
                      });
                    }
                    break;
                  }
                  case 401: {
                    const responseData: MemberApiErrorResponse = (await response.json()) as MemberApiErrorResponse;
                    if (responseData?.detail === 'Missing Session Details') {
                      onCloseMFAModal();
                      dispatch({
                        type: 'FETCH_FAILURE',
                        error: new Error('Your session has expired. Please try again.'),
                      });
                    }
                    break;
                  }
                  default: {
                    setErrorMessage(GENERIC_ERROR_MESSAGE);
                    break;
                  }
                }
              })
              .catch(() => setErrorMessage(GENERIC_ERROR_MESSAGE))
              .finally(() => setIsMFACallLoading(false));
          }}
          handleResend={() =>
            challengeSMS({ member_id: memberId, mfa_type: 'sms', mfa_token: '' }).catch(() =>
              setErrorMessage('Unable to resend code'),
            )
          }
          onEditPhoneNumber={() => {
            setChallengeMode(false);
            setEnrollmentMode(true);
          }}
          setMFACode={setMFACode}
          errorMessage={errorMessage}
          isLoading={isMFACallLoading}
        />
      )}
      <FinalForm<FormValues>
        decorators={[focusOnErrorsDecorator]}
        validate={validate}
        subscription={{ submitting: true }}
        onSubmit={async (values: { email: string; password: string }): Promise<void> => {
          try {
            dispatch({ type: 'FETCH_INIT' });
            const userData = await loginMFA({ organization_id: Number(organizationId), ...values });

            if (!userData?.token) {
              setMemberId(userData?.memberId);
              if (userData?.mfa?.name) {
                setPhoneNumber(userData?.mfa?.name);
                setChallengeMode(true);
              } else {
                setEnrollmentMode(true);
              }

              return;
            }

            getLoginInformation(userData).finally(() => {});
          } catch (e) {
            dispatch({
              type: 'FETCH_FAILURE',
              error: new Error('Sign in failed. Verify your email and password and try again.'),
            });
            if (e instanceof Error) window.console.error('Login failure: ', e.message);
          }
        }}
      >
        {({ handleSubmit, submitting }): ReactNode => (
          <form noValidate onSubmit={handleSubmit}>
            <Box pb={2}>
              <Typography variant="h4" gutterBottom className={classes.pageTitle}>
                Welcome Back!
              </Typography>
              <Typography className={classes.pageDescription}>
                View and update your applications from the {partnerName} Hub.
              </Typography>
            </Box>

            <GridBox>
              <AuthStep
                title="Sign In"
                leftLabelCols={6}
                rightLabelCols={6}
                backTo={false}
                auth={{
                  prelabel: "Don't have an account?",
                  label: 'Create one.',
                  assignRoute: `${config.businessLoanAppPublicUrl}/register`,
                }}
              >
                <GridRow className={cx(classes.emailField, classes.gridRow)}>
                  <GridCol>
                    <TextField
                      name={emailProperty}
                      label={LoginLabels.EMAIL}
                      autoFocus
                      showError={showErrorOnBlur}
                      fieldProps={{
                        validate: emailValidator,
                      }}
                    />
                  </GridCol>
                </GridRow>
                <GridRow>
                  <GridCol>
                    <PasswordField name={passwordProperty} label={LoginLabels.PASSWORD} />
                    <Box mt={0.5}>
                      <Typography display="inline" variant="caption">
                        Forgot your password?&nbsp;
                      </Typography>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <Link
                        aria-label={LoginLabels.ARIA_RESET_PASSWORD}
                        className={classes.resetLink}
                        type="button"
                        component="button"
                        variant="caption"
                        color="secondary"
                        onClick={() => {
                          setResettingPassword(true);
                        }}
                      >
                        Reset it
                      </Link>
                    </Box>
                  </GridCol>
                </GridRow>
              </AuthStep>
            </GridBox>

            <Box mt={2} className={classes.agreementText}>
              <PrimaryButton type="submit" disabled={submitting} onClick={handleSubmit}>
                Sign In
              </PrimaryButton>

              {error && (
                <Box mt={0.5}>
                  <Typography paragraph color="error">
                    {error.message}
                  </Typography>
                </Box>
              )}
            </Box>

            {resettingPassword && (
              <PasswordResetModal
                isOpen={resettingPassword}
                onClose={() => setResettingPassword(false)}
                resetPassword={resetPassword}
                organizationId={Number(organizationId)}
              />
            )}
            {loading && <div className={classes.agreementText}>Loading...</div>}
          </form>
        )}
      </FinalForm>
    </>
  );
};
