import { SessionUser } from '@streetshares/frontend-common';

export type Action =
  | {
      type: 'FETCH_INIT';
    }
  | {
      type: 'FETCH_FAILURE';
      error: Error;
    }
  | {
      type: 'FETCH_SUCCESS';
      data: SessionUser & { appId: number | undefined };
    }
  | {
      type: 'FETCH_RESET';
    };

type State = {
  loading: boolean;
  data: SessionUser | undefined;
  error: Error | null;
};

export const INITIAL_STATE: State = {
  loading: false,
  data: undefined,
  error: null,
};

export const LOADING_STATE: State = {
  loading: true,
  data: undefined,
  error: null,
};

export const asyncReducer = (state: State, action: Action): State => {
  const { type } = action;
  switch (action.type) {
    case 'FETCH_RESET':
      return INITIAL_STATE;
    case 'FETCH_INIT':
      return LOADING_STATE;
    case 'FETCH_SUCCESS':
      return {
        ...INITIAL_STATE,
        data: action.data,
      };
    case 'FETCH_FAILURE':
      return {
        ...INITIAL_STATE,
        error: action.error,
      };
    default: {
      throw new Error(`Invalid action dispatched: ${type}.`);
    }
  }
};
