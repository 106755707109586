import { Button, makeStyles } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

export const useStyles = makeStyles(() => ({
  closeButton: {
    minWidth: 0,
    width: '35px',
    height: '35px',
    borderRadius: '35px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    cursor: ' pointer',
    '&:hover': {
      borderRadius: '35px',
      boxShadow: ' 0 0px 0px 0 rgba(0,0,0,0.5)',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
}));

type CloseButtonProps = {
  label: string;
  onClick: () => void;
};

export const CloseButton: React.FC<CloseButtonProps> = ({ label, onClick }) => {
  const classes = useStyles();

  return (
    <Button className={classes.closeButton} aria-label={label} onClick={onClick}>
      <CloseIcon />
    </Button>
  );
};
