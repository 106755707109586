import { Dispatch, FC, FormEvent, SetStateAction } from 'react';
import { GridCol, PrimaryButton, Logo, AuthorizationModal } from '@streetshares/frontend-common';
import { Typography, makeStyles, Box, TextField, InputAdornment, TextFieldProps } from '@material-ui/core';
import InputMask, { Props } from 'react-input-mask';

export const useStyles = makeStyles(({ palette }) => ({
  icon: {
    fontSize: '4.5rem',
    color: palette.primary.main,
  },
  text: {
    fontSize: '1rem',
  },
  leftButton: {
    marginRight: '1rem',
  },
  title: {
    fontSize: '1.5rem',
  },
  closeIcon: {
    color: palette.grey['500'],
    fontSize: '1.8rem',
    '&:hover': {
      opacity: 0.7,
      cursor: 'pointer',
    },
  },
  logo: {
    margin: '0px 0px 16px',
    height: 'unset',
  },
}));

type RemoveAccountConfirmProps = {
  open: boolean;
  handleContinue: () => void;
  onClose: () => void;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  logoUrl?: string;
  errorMessage?: string;
  isLoading: boolean;
};

type ActionsProps = {
  handleContinue: () => void;
  isLoading: boolean;
};

type ErrorProps = {
  errorMessage?: string | null;
};

type ContentProps = {
  setPhoneNumber: Dispatch<SetStateAction<string>>;
};

const Header = ({ logoUrl }: { logoUrl?: string }) => {
  const classes = useStyles();
  return (
    <div>
      <Logo src={logoUrl} className={classes.logo} />
      <Typography className={classes.title}>Secure Your Account</Typography>
    </div>
  );
};

const Content: FC<ContentProps> = ({ setPhoneNumber }) => {
  const classes = useStyles();

  const handleOnInput = (e: FormEvent) => {
    const target = e.target as HTMLInputElement;
    setPhoneNumber(target.value);
  };

  return (
    <GridCol>
      <Typography className={classes.text}>
        Enter your phone number to receive an SMS text message with a 6-digit code:
      </Typography>
      <Box pt={3} pb={2}>
        <InputMask mask="999-999-9999" name="verification_phone" onChange={handleOnInput}>
          {(inputProps: Props & TextFieldProps) => (
            <TextField
              {...inputProps}
              InputProps={{
                startAdornment: <InputAdornment position="start">+1</InputAdornment>,
              }}
              label="Phone Number"
            />
          )}
        </InputMask>
      </Box>
    </GridCol>
  );
};

const Actions: FC<ActionsProps> = ({ handleContinue, isLoading }) => {
  return (
    <PrimaryButton disabled={isLoading} onClick={handleContinue}>
      Continue
    </PrimaryButton>
  );
};

const ErrorMessage: FC<ErrorProps> = ({ errorMessage }) => {
  return <Typography>{errorMessage || ''}</Typography>;
};

export const PhoneAuthorizationModal: FC<RemoveAccountConfirmProps> = ({
  open,
  handleContinue,
  onClose,
  setPhoneNumber,
  logoUrl,
  errorMessage,
  isLoading,
}) => {
  return (
    <AuthorizationModal
      onClose={onClose}
      open={open}
      header={<Header logoUrl={logoUrl} />}
      content={<Content setPhoneNumber={setPhoneNumber} />}
      actions={<Actions handleContinue={handleContinue} isLoading={isLoading} />}
      error={<ErrorMessage errorMessage={errorMessage} />}
    />
  );
};
