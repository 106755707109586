import { ReactElement, useMemo } from 'react';
import {
  GridBox,
  GridRow,
  GridCol,
  currencyFormatter,
  formatDate,
  DATE_RELATIVE,
  percentRateFormatter,
  PrimaryButton,
  TextButton,
} from '@streetshares/frontend-common';
import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';
import { parseISO } from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { ApplicationStatus, HubBusinessApplication, ProductsToApply } from 'Types/app';
import { config } from 'config';
import { ApplicationStatusType } from 'constants/enums';
import { BLARoutes, redirectToBLA } from 'utils/utils';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    width: '100%',
    height: 'auto',
    borderRadius: '8px',
    marginBottom: '30px',
    boxShadow: '0 2px 8px 0 rgba(0,0,0,0.3)',
    backgroundColor: '#E9F6EE',
    border: `1px solid ${palette.grey[300]}`,
    padding: '15px 15px 30px 15px',
    cursor: 'default',
  },
  title: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginBottom: 0,
    paddingBottom: '0!important',
    '& h2': {
      marginLeft: '10px',
      fontWeight: 'bold',
      fontSize: '1.125rem',
      transform: 'translate(9px,-6px)',
      letterSpacing: '0.5px',
    },
  },
  icon: {
    color: '#25A95D', // TODO: fix the color
    fontSize: '30px',
    transform: 'translate(4px,5px)',
  },
  tableContainer: {
    padding: '12px 0 0 30px!important',
  },
  table: {
    width: '536px',
    marginLeft: '30px!important',
    marginTop: '10px',
    borderStyle: 'hidden',
    '& thead': {
      borderStyle: 'hidden',
    },
    '& th': {
      fontSize: '1.125rem',
      '&:first-of-type': {
        fontSize: '1.5rem',
        fontWeight: 'bold',
      },
      paddingBottom: 0,
      paddingLeft: 0,
    },
    '& td': {
      paddingLeft: 0,
      paddingTop: 0,
      fontSize: '0.75rem',
    },
  },
  disclaimerText: {
    marginLeft: '30px',
    fontWeight: 400,
  },
  bottomContainer: {
    display: 'flex',
  },
  viewMoreButton: {
    marginLeft: 'auto',
    marginRight: '8px',
    color: palette.common.white,
    backgroundColor: '#25A95D',
    width: '149.75px',
    minWidth: '149.75px',
    height: '45px',
    '&:hover': {
      backgroundColor: '#9CAE29',
    },
  },
  link: {
    marginLeft: '33px',
    display: 'flex',
    color: '#2087F4',
    '&:hover': {
      color: '#1244AF',
    },
    '&:disabled': {
      color: '#C4C4C4',
    },
  },
  linkText: {
    fontWeight: 400,
    fontFamily: 'Roboto',
    marginLeft: 5,
  },
}));

type ProductProps = {
  name: string;
  historyHeaders: Array<string>;
  historyValues: Array<string>;
  icon: ReactElement;
};

type ProductCardProps = {
  productToApply: ProductsToApply;
  offer: HubBusinessApplication['offer'];
  appId?: number;
  applicationStatus?: ApplicationStatus;
  hasActiveCovenantDocumentRequests?: boolean;
  withCustomerUX: boolean;
};

const ApplicationStatusMessages = {
  [ApplicationStatusType.WAITING_FOR_ACCEPTANCE]: 'Your offer for business credit is ready',
  [ApplicationStatusType.READY_TO_CLOSE]: 'Please respond to lender’s closing instructions',
  [ApplicationStatusType.READY_FOR_FINAL]: 'Lender is ready to close your loan',
  [ApplicationStatusType.PRE_BOARDING]: 'Your Loan application is complete',
  [ApplicationStatusType.COMPLETE]: 'Your Loan application is complete',
};

export const OfferReadyCard: React.FC<ProductCardProps> = ({
  productToApply,
  offer,
  appId,
  applicationStatus,
  hasActiveCovenantDocumentRequests,
  withCustomerUX,
}) => {
  const classes = useStyles();
  const { enableCovenantBorrowerHub } = useFlags();

  const HISTORY_HEADERS_BLX_CUSTOMER_UX = [
    currencyFormatter(String(offer?.amount ?? '')),
    `${offer?.term ?? 0} months`,
    percentRateFormatter(String(offer?.rate ?? '')),
    formatDate(parseISO(offer?.expiration_date ?? ''), DATE_RELATIVE),
    String(appId),
  ];
  const HISTORY_VALUES_BLX_CUSTOMER_UX = ['Amount', 'Term', 'Rate', 'Expiration date', 'Application ID'];

  const products: Record<ProductsToApply, ProductProps> = {
    BLX: {
      name: ApplicationStatusMessages[applicationStatus ?? ApplicationStatusType.WAITING_FOR_ACCEPTANCE],
      historyHeaders: withCustomerUX ? HISTORY_HEADERS_BLX_CUSTOMER_UX : [],
      historyValues: withCustomerUX ? HISTORY_VALUES_BLX_CUSTOMER_UX : [],
      icon: <CheckCircleOutline className={classes.icon} />,
    },
    CREDIT_CARD: {
      name: 'Your offer for a Credit Card is ready',
      historyHeaders: ['$125,000', '18 months', '15.5%', '20 Dec 2021'],
      historyValues: ['Amount', 'Term', 'Rate', 'Expiration date'],
      icon: <CheckCircleOutline className={classes.icon} />,
    },
  };

  const enableCovenantDocumentsRedirect = useMemo((): boolean => {
    return (enableCovenantBorrowerHub &&
      hasActiveCovenantDocumentRequests &&
      applicationStatus &&
      [
        ApplicationStatusType.COMPLETE,
        ApplicationStatusType.READY_TO_CLOSE,
        ApplicationStatusType.WAITING_FOR_ACCEPTANCE,
        ApplicationStatusType.READY_FOR_FINAL,
        ApplicationStatusType.PRE_BOARDING,
      ].includes(applicationStatus)) as boolean;
  }, [enableCovenantBorrowerHub, hasActiveCovenantDocumentRequests, applicationStatus]);

  return (
    <GridBox className={classes.root}>
      <GridRow container>
        <GridCol className={classes.title} item md={12} lg={12}>
          {products[productToApply].icon}
          <Typography className={classes.title} variant="subtitle2" component="h2">
            {products[productToApply].name} {!withCustomerUX && `(ID ${appId ?? ''})`}
          </Typography>
        </GridCol>
        <GridCol className={classes.tableContainer} item md={12} lg={12}>
          <Table className={classes.table} size="small" aria-label="history description table">
            <TableHead>
              <TableRow>
                {products[productToApply].historyHeaders.map((header) => (
                  <TableCell key={header} align="left">
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {products[productToApply].historyValues.map((value) => (
                  <TableCell key={value} align="left">
                    {value}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
          <Typography className={classes.disclaimerText} variant="body2" component="h3">
            Additional terms and conditions apply
          </Typography>
        </GridCol>
        <GridCol className={classes.bottomContainer} item md={12} lg={12}>
          {enableCovenantDocumentsRedirect && (
            <TextButton className={classes.link} onClick={() => redirectToBLA(appId, BLARoutes.COVENANT_REQUEST)}>
              <Typography variant="body2" className={classes.linkText}>
                Add Covenant Documents
              </Typography>
            </TextButton>
          )}
          {withCustomerUX && (
            <PrimaryButton
              className={classes.viewMoreButton}
              onClick={() => {
                if (appId) {
                  window.location.assign(`${config.offerClosingAppPublicUrl}/${appId}`);
                }
              }}
            >
              View Details
            </PrimaryButton>
          )}
        </GridCol>
      </GridRow>
    </GridBox>
  );
};
