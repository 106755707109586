import { createContext } from 'react';

export type Branding = {
  logoUrl: string;
  brandUrl: string;
  partnerName: string;
  privacyPolicyUrl: string;
  organizationId: number;
  contactEmail: string;
  contactPhoneNumber: string | null;
  muiThemeTypeCookie: string | null;
  setMuiThemeTypeCookie: (newValue: string, options?: Cookies.CookieAttributes | undefined) => void;
  partnerEmail?: string;
  partnerPhone?: string;
};
export const BrandingContext = createContext<Partial<Branding>>({});
