import { useState, Dispatch, FC, FormEvent, SetStateAction } from 'react';
import { Typography, makeStyles, Box, OutlinedInput, OutlinedInputProps } from '@material-ui/core';
import { AuthorizationModal, GridCol, Logo, PrimaryButton } from '@streetshares/frontend-common';
import InputMask, { Props } from 'react-input-mask';

export const useStyles = makeStyles(({ palette }) => ({
  icon: {
    fontSize: '4.5rem',
    color: palette.primary.main,
  },
  text: {
    fontSize: '1rem',
  },
  title: {
    fontSize: '1.5rem',
  },
  logo: {
    margin: '0px 0px 16px',
    height: 'unset',
  },
  input: {
    height: '46px',
    width: '250px',
    margin: 'auto',
    color: 'black !important',
  },
  link: {
    fontWeight: 400,
    fontSize: '1rem',
    color: '#0076D1',
    display: 'inline',
    cursor: 'pointer',
    '&:hover': {
      fontWeight: 700,
    },
  },
  footerContainer: {
    marginTop: '12px',
  },
  continueButton: {
    width: '250px',
  },
}));

export type ChallengeModalProps = {
  open: boolean;
  handleContinue: () => void;
  onClose: () => void;
  handleResend: () => void;
  onEditPhoneNumber: () => void;
  maskedPhoneNumber?: string;
  logoUrl?: string;
  comesFromEnrollment: boolean;
  setMFACode: Dispatch<SetStateAction<string>>;
  errorMessage?: string;
  isLoading: boolean;
};

type HeaderProps = {
  logoUrl?: string;
};

type ContentProps = {
  onEditPhoneNumber: () => void;
  maskedPhoneNumber?: string;
  comesFromEnrollment: boolean;
  setMFACode: Dispatch<SetStateAction<string>>;
  resendMessage: boolean;
};

type ActionsProps = {
  handleContinue: () => void;
  handleResend: () => void;
  isLoading: boolean;
  setResendMessage: Dispatch<SetStateAction<boolean>>;
};

type ErrorProps = {
  errorMessage?: string | null;
};

const Header: FC<HeaderProps> = ({ logoUrl }) => {
  const classes = useStyles();
  return (
    <div>
      <Logo src={logoUrl} className={classes.logo} />
      <Typography className={classes.title}>Verify Your Identity!</Typography>
    </div>
  );
};

const Content: FC<ContentProps> = ({
  /* onEditPhoneNumber, */ maskedPhoneNumber,
  comesFromEnrollment,
  setMFACode,
  resendMessage,
}) => {
  const classes = useStyles();

  const handleOnInput = (e: FormEvent) => {
    const target = e.target as HTMLInputElement;
    setMFACode(target.value);
  };

  return (
    <GridCol>
      <Typography className={classes.text}>This code is valid for 5 minutes.</Typography>
      <Typography className={classes.text}>
        {resendMessage ? 'Code has been resent. Please try validating again.' : "We've sent the text message to:"}
      </Typography>
      <Box pt={3} pb={2}>
        <InputMask
          disabled
          value={comesFromEnrollment ? `XXXXXXXX${maskedPhoneNumber?.slice(-4) ?? ''}` : maskedPhoneNumber}
          mask="+X XXX-XXX-9999"
          name="recovery_code"
        >
          {(inputProps: Props & OutlinedInputProps) => (
            <OutlinedInput
              {...inputProps}
              inputProps={{ min: 0, style: { textAlign: 'center', fontSize: '1.1rem' } }}
              className={classes.input}
              disabled
              // Feature disabled for now
              /*               endAdornment={
                comesFromEnrollment && (
                  <Typography onClick={onEditPhoneNumber} className={classes.link}>
                    Edit
                  </Typography>
                )
              } */
            />
          )}
        </InputMask>
      </Box>
      <Box>
        <InputMask mask="999999" name="recovery_code" onChange={handleOnInput}>
          {(inputProps: Props & OutlinedInputProps) => (
            <OutlinedInput
              {...inputProps}
              inputProps={{ min: 0, style: { textAlign: 'center', fontSize: '1.1rem' } }}
              placeholder="Enter the 6-digit code"
              className={classes.input}
            />
          )}
        </InputMask>
      </Box>
    </GridCol>
  );
};

const Actions: FC<ActionsProps> = ({ handleContinue, handleResend, isLoading, setResendMessage }) => {
  const classes = useStyles();
  return (
    <>
      <PrimaryButton disabled={isLoading} className={classes.continueButton} onClick={handleContinue}>
        Continue
      </PrimaryButton>
      <Box className={classes.footerContainer}>
        <Typography className={classes.text}>
          Didn&apos;t receive a code?{' '}
          <Typography
            className={classes.link}
            onClick={() => {
              handleResend();
              setResendMessage(true);
            }}
          >
            Resend
          </Typography>
        </Typography>
      </Box>
    </>
  );
};

const ErrorMessage: FC<ErrorProps> = ({ errorMessage }) => {
  return <Typography>{errorMessage || ''}</Typography>;
};

export const ChallengeModal: FC<ChallengeModalProps> = ({
  open,
  handleContinue,
  onClose,
  logoUrl,
  handleResend,
  onEditPhoneNumber,
  maskedPhoneNumber,
  comesFromEnrollment,
  setMFACode,
  errorMessage,
  isLoading,
}) => {
  const [resendMessage, setResendMessage] = useState(false);

  return (
    <AuthorizationModal
      onClose={onClose}
      open={open}
      header={<Header logoUrl={logoUrl} />}
      content={
        <Content
          setMFACode={setMFACode}
          onEditPhoneNumber={onEditPhoneNumber}
          maskedPhoneNumber={maskedPhoneNumber}
          comesFromEnrollment={comesFromEnrollment}
          resendMessage={resendMessage}
        />
      }
      actions={
        <Actions
          handleContinue={handleContinue}
          handleResend={handleResend}
          isLoading={isLoading}
          setResendMessage={setResendMessage}
        />
      }
      error={<ErrorMessage errorMessage={errorMessage} />}
    />
  );
};
