import { useContext } from 'react';

import { AuthContext, AuthContextProps } from './AuthContext';

export const useAuth = (): Partial<AuthContextProps> => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider.');
  }

  return context;
};
