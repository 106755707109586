import React, { useMemo } from 'react';

import { BrandingContext, Branding } from './BrandingContext';

export const BrandingProvider: React.FC<Branding> = ({
  children,
  logoUrl,
  brandUrl,
  partnerName,
  privacyPolicyUrl,
  organizationId,
  contactEmail,
  contactPhoneNumber,
  muiThemeTypeCookie,
  setMuiThemeTypeCookie,
}) => {
  const brandingContext = useMemo(
    () => ({
      logoUrl,
      brandUrl,
      partnerName,
      privacyPolicyUrl,
      organizationId,
      contactEmail,
      contactPhoneNumber,
      muiThemeTypeCookie,
      setMuiThemeTypeCookie,
    }),
    [
      brandUrl,
      contactEmail,
      contactPhoneNumber,
      logoUrl,
      muiThemeTypeCookie,
      organizationId,
      partnerName,
      privacyPolicyUrl,
      setMuiThemeTypeCookie,
    ],
  );

  return <BrandingContext.Provider value={brandingContext}>{children}</BrandingContext.Provider>;
};
