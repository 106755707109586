import { ReactNode } from 'react';
import { Typography, GridSize, makeStyles } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { GridRow, GridCol, TextButton } from '@streetshares/frontend-common';

const useStyles = makeStyles(({ palette }) => ({
  authCol: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  formLabel: {
    lineHeight: '2.5rem',
    '& .MuiButton-label': {
      color: palette.secondary.main,
      minWidth: 'unset',
      fontWeight: 500,
      fontFamily: 'Roboto',
    },
    '& a': {
      color: palette.secondary.main,
    },
    '& button': {
      padding: '4px 1px',
    },
  },
}));

type Props = {
  title?: string;
  leftLabelCols?: GridSize;
  rightLabelCols?: GridSize;
  backTo: boolean;
  auth: {
    prelabel?: string;
    label: string;
    route?: string;
    assignRoute?: string;
  };
  children: ReactNode;
};
export const AuthStep: React.FC<Props> = ({
  title,
  leftLabelCols = 4,
  rightLabelCols = 8,
  backTo = false,
  auth: { prelabel, label, route, assignRoute },
  children,
}) => {
  const classes = useStyles();

  return (
    <>
      <GridRow>
        <GridCol xs={leftLabelCols} sm={leftLabelCols} cols={leftLabelCols}>
          {backTo ? (
            <Typography variant="subtitle2" className={classes.formLabel}>
              {route ? (
                <NavLink to={route}>{label}</NavLink>
              ) : (
                <TextButton onClick={() => window.location.assign(assignRoute || '')}>{label}</TextButton>
              )}
            </Typography>
          ) : (
            <Typography variant="subtitle1" className={classes.formLabel}>
              {title}
            </Typography>
          )}
        </GridCol>

        {!backTo ? (
          <GridCol xs={rightLabelCols} sm={rightLabelCols} cols={rightLabelCols} className={classes.authCol}>
            <Typography variant="body2" className={classes.formLabel}>
              {prelabel}&nbsp;
            </Typography>

            <Typography variant="subtitle2" className={classes.formLabel}>
              {route ? (
                <NavLink to={route}>{label}</NavLink>
              ) : (
                <TextButton onClick={() => window.location.assign(assignRoute || '')}>{label}</TextButton>
              )}
            </Typography>
          </GridCol>
        ) : null}
      </GridRow>

      {children}
    </>
  );
};
