import React from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { logger } from '@streetshares/frontend-common';

import { config } from 'config';
import { ErrorFallback } from 'components/Common/ErrorFallback';
import { CustomerHubApp } from 'App';

import './index.scss';

datadogRum.init({
  applicationId: '9e04987a-fabc-46be-a32b-5c44cfaaff04',
  clientToken: 'pube4d3bae1dc8be124c1c8f1ffcdb56a08',
  site: 'datadoghq.com',
  service: config.appTitle,
  env: config.environmentName,
  version: config.buildTag,
  sampleRate: 100,
  trackInteractions: true,
  allowedTracingOrigins: [/https:\/\/.*\.streetshares\.com/],
});

datadogLogs.init({
  clientToken: 'pubcc8883eed3f2a7c35d8d9f092000303a',
  site: 'datadoghq.com',
  forwardErrorsToLogs: false,
  sampleRate: 100,
  env: config.environmentName,
  service: config.appTitle,
  version: config.buildTag,
});

datadogLogs.addLoggerGlobalContext('appName', config.appTitle);
datadogLogs.addLoggerGlobalContext('appVersion', config.buildTag);

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(theError: Error) =>
        logger.error('Error boundary encountered', {
          errorText: theError.message,
        })
      }
    >
      <CustomerHubApp />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);
