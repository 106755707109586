import { FC } from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';

const useStyles = makeStyles(({ palette }) => ({
  container: {
    backgroundColor: '#FCE8EA',
    height: '30px',
    padding: '0',
    border: 'none',
    borderRadius: '6px 6px 0px 0px',
    display: 'flex',
  },
  text: {
    color: palette.error.main,
    fontSize: '0.875rem',
    margin: '5px 0px 0px 152px',
    display: 'block',
  },
  link: {
    color: palette.common.black,
    padding: 0,
    '& span': {
      transform: 'translate(3px, -2px)',
      fontFamily: 'Roboto',
      fontSize: '0.875rem',
      fontWeight: 500,
      minWidth: '0',
      padding: 0,
    },
  },
}));

type ApplicationMessageBoxProps = {
  message: string[] | string;
};

export const ApplicationMessageBox: FC<ApplicationMessageBoxProps> = ({ message }) => {
  const classes = useStyles();
  const { enableShowApplicationMessageInCustomerHub } = useFlags();

  if (!enableShowApplicationMessageInCustomerHub) {
    return null;
  }

  const messageToDisplay = Array.isArray(message) && message.length > 0 ? message[0] : message;
  if (!messageToDisplay || messageToDisplay === '') {
    return null;
  }

  return (
    <Box className={classes.container}>
      <Typography component="span" variant="body1" className={classes.text}>
        {messageToDisplay}
      </Typography>
    </Box>
  );
};
