import { makeStyles, Box } from '@material-ui/core';
import cx from 'classnames';
import { HintDrawer, useHintDrawer } from '@streetshares/frontend-common';

const useStyles = makeStyles(({ transitions, breakpoints }) => ({
  content: ({ drawerWidth }: { drawerWidth: number }) => ({
    flexGrow: 1,
    transition: transitions.create('margin', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,

    [breakpoints.down(1600)]: {
      marginRight: 0,
    },
  }),
  // We need to have this written as a function too, because otherwise it won't override above rules
  contentShift: () => ({
    transition: transitions.create('margin', {
      easing: transitions.easing.easeOut,
      duration: transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  }),
}));

export const AppWrapper: React.FC = ({ children }) => {
  const { drawerWidth, isDrawerOpen } = useHintDrawer();
  const classes = useStyles({ drawerWidth });

  return (
    <>
      <a href="#main-content" className="skip-to-link">
        Skip to main content
      </a>
      <a href="#footer" className="skip-to-link">
        Skip to footer
      </a>

      <Box display="flex">
        <Box
          style={{ maxWidth: '100%' }}
          className={cx(classes.content, {
            [classes.contentShift]: isDrawerOpen,
          })}
        >
          {children}
        </Box>

        <HintDrawer />
      </Box>
    </>
  );
};
