const {
  REACT_APP_BASENAME,
  REACT_APP_ENV_NAME,
  REACT_APP_DEVMODE,
  REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
  REACT_APP_BUILD_TAG,
  REACT_APP_API_BASEURL,
  REACT_APP_SMARTYSTREETS_API_KEY,
  REACT_APP_OFFER_CLOSING_APP_PUBLIC_URL,
  REACT_APP_BUSINESS_LOAN_APP_PUBLIC_URL,
  REACT_APP_SYSTEM_STATUS_URL,
  REACT_APP_SYSTEM_STATUS_PAGE_LINK,
  REACT_APP_SYSTEM_STATUS_PATH_AUTH_TOKEN,
} = process.env;

export type EnvironmentName = 'dev' | 'okteto' | 'staging' | 'qa' | 'prod';

export const config = {
  basename: String(REACT_APP_BASENAME),
  appTitle: 'Customer Hub',
  devMode: REACT_APP_DEVMODE === 'true',
  launchdarklyID: String(REACT_APP_LAUNCH_DARKLY_CLIENT_ID),
  environmentName: String(REACT_APP_ENV_NAME) as EnvironmentName,
  buildTag: String(REACT_APP_BUILD_TAG || 'DEVMODE'),
  lightModeFavicon: 'https://s3.amazonaws.com/public.streetshares.com/assets/images/favicons/PPP_Fav_16px.ico',
  darkModeFavicon: 'https://s3.amazonaws.com/public.streetshares.com/assets/images/favicons/PPP_DKFav_16px.ico',
  apiBaseURL: String(REACT_APP_API_BASEURL),
  smartyStreetsApiKey: String(REACT_APP_SMARTYSTREETS_API_KEY),
  systemStatusUrl: String(REACT_APP_SYSTEM_STATUS_URL),
  systemStatusPageLink: String(REACT_APP_SYSTEM_STATUS_PAGE_LINK),
  systemStatusPageAuthToken: String(REACT_APP_SYSTEM_STATUS_PATH_AUTH_TOKEN),
  offerClosingAppPublicUrl: String(REACT_APP_OFFER_CLOSING_APP_PUBLIC_URL),
  businessLoanAppPublicUrl: String(REACT_APP_BUSINESS_LOAN_APP_PUBLIC_URL),
};
