/* eslint-disable react/no-children-prop */
// Rule above disabled beacuse children= is the recommended syntax in react-router 5.1, gets replaced with element= in 6.0
import { useLayoutEffect } from 'react';
import { SWRConfig } from 'swr';
import { Redirect, Switch, useLocation } from 'react-router-dom';
import { getFetcherConfig } from '@streetshares/frontend-common';

import { useBranding } from 'branding/useBranding';
import { HomeScreen } from 'screens/HomeScreen';
import { BusinessFormScreen } from 'screens/BusinessFormScreen';
import { AuthenticatedAppRoutes } from 'routes/AppRoutes';
import { useAuth } from 'auth/useAuth';
import { fetch } from 'api/fetch';

import { Route } from './Route';

export const AuthRouter: React.FC = () => {
  const { user } = useAuth();
  const { partnerName } = useBranding();
  const { pathname } = useLocation();

  // Scroll up the page on route change.
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <SWRConfig value={getFetcherConfig({ user, fetch })}>
      <Switch>
        <Route
          exact
          path={AuthenticatedAppRoutes.Home}
          children={<HomeScreen />}
          title={`${partnerName ?? ''} Hub - Home`}
        />
        <Route
          exact
          path={AuthenticatedAppRoutes.Business}
          children={<BusinessFormScreen />}
          title={`${partnerName ?? ''} Hub - Business`}
        />
        <Redirect exact to={AuthenticatedAppRoutes.Home} />
      </Switch>
    </SWRConfig>
  );
};
