import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import { GridRow, GridCol, NeedAssistance, Loader } from '@streetshares/frontend-common';
import useSwr from 'swr';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useBranding } from 'branding/useBranding';
import { AddNewBusiness } from 'components/Common/AddNewBusiness';
import { useProductConfig } from 'api/productConfig';
import { Layout } from 'components/Layout/Layout';
import { HubBusiness, HubBusinessApplicationsGroup } from 'Types/app';
import { ProductSettingsBLX, ProductTypes } from 'Types/enums';
import { ApiEndpoint } from 'api/businessEndpoints';

import { BusinessContainer } from './BusinessContainer';

export const useStyles = makeStyles(({ palette, breakpoints }) => ({
  container: {
    borderTop: `1px solid ${palette.common.white}`,
    paddingBottom: '2.75rem',
  },
  gridCol: {
    marginTop: '1.5rem',
    marginBottom: '0.25rem',
  },
  mbContact: {
    [breakpoints.down('md')]: {
      marginBottom: '2.5rem',
    },
  },
  mtView: {
    marginTop: '0.5rem',
  },
}));

export const HomeScreen: React.FC = () => {
  const classes = useStyles();
  const { contactEmail, contactPhoneNumber } = useBranding();
  const { data: businesses } = useSwr<Array<HubBusiness>>(ApiEndpoint.listBusiness);
  const { data: businessApplicationsGroup } = useSwr<Array<HubBusinessApplicationsGroup>>(ApiEndpoint.listApplications);
  const { enableAddNewBusiness } = useFlags();

  const {
    productConfig: { settings },
  } = useProductConfig(ProductTypes.BLX);

  if (!businesses) {
    return <Loader message="Loading the Hub" />; // TODO: LAAS-7553: put a modal instead of this Loader
  }

  return (
    <Layout>
      <Container className={classes.container} maxWidth="lg">
        <GridRow spacing={4} container>
          <GridCol className={classes.gridCol} sm={12} md={8} lg={8} item>
            <Typography variant="h1">Your Businesses</Typography>
          </GridCol>
        </GridRow>
        <GridRow spacing={6} container>
          <GridCol item sm={12} md={8} lg={8}>
            {businesses?.map((business) => (
              <BusinessContainer
                key={business.id}
                businessApplicationGroup={businessApplicationsGroup?.find((bag) => bag.business_id === business.id)}
                business={business}
              />
            ))}
            {enableAddNewBusiness && (
              <Box display="flex" justifyContent="center" alignItems="center" minHeight="4vh">
                <AddNewBusiness />
              </Box>
            )}
          </GridCol>
          <GridCol item sm={12} md={4} lg={4}>
            <NeedAssistance
              email={settings[ProductSettingsBLX.SUPPORT_EMAIL] ?? contactEmail}
              phone={settings[ProductSettingsBLX.SUPPORT_PHONE] ?? contactPhoneNumber ?? undefined}
            />
          </GridCol>
        </GridRow>
      </Container>
    </Layout>
  );
};
