import { logoutUser } from 'auth/logout';

export type FetchOptions = RequestInit & {
  url: string;
};

export type Headers = RequestInit['headers'];

export const getAuthHeaders = (token: string): Headers => ({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`,
});

export async function fetch(input: RequestInfo, init?: RequestInit): Promise<Response> {
  const response = await window.fetch(input, init);
  if (response.status === 401) {
    logoutUser();
  }

  return response;
}
