import { makeStyles, Typography } from '@material-ui/core';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import { generatePath, Link } from 'react-router-dom';

import { AuthenticatedAppRoutes } from 'routes/AppRoutes';

const useClasses = makeStyles({
  link: {
    display: 'flex',
    color: '#2087F4',
    '&:hover': {
      color: '#1244AF',
    },
  },
  linkText: {
    fontWeight: 400,
    fontFamily: 'Roboto',
    marginLeft: 5,
  },
});

export const AddNewBusiness: React.FC = () => {
  const classes = useClasses();

  return (
    <Link className={classes.link} to={generatePath(AuthenticatedAppRoutes.Business)}>
      <AddCircleRoundedIcon />
      <Typography variant="body2" className={classes.linkText}>
        Add New Business
      </Typography>
    </Link>
  );
};
