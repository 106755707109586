/* eslint-disable scanjs-rules/identifier_localStorage */
/* eslint-disable scanjs-rules/identifier_sessionStorage */
import Cookies, { CookieAttributes } from 'js-cookie';
import { SessionUser } from '@streetshares/frontend-common';

import { config } from 'config';

/* istanbul ignore next */
export const cookieOptions = {
  path: '/',
  domain: window.location.hostname,
  sameSite: 'strict' as CookieAttributes['sameSite'],
  secure: !config.devMode, // Local dev doesn't support HTTPS right now
};

export const clearCredentials = (): void => {
  try {
    Cookies.remove('jwtToken', cookieOptions);
    Cookies.remove('memberId', cookieOptions);
    Cookies.remove('tl_application_id', cookieOptions);
    Cookies.remove('lf_application_id', cookieOptions);
    localStorage.clear();
    sessionStorage.clear();
  } catch {} // eslint-disable-line no-empty
};

export type CredentialsT = { token: string; memberId: string };
export const loadCredentials = (): CredentialsT | undefined => {
  try {
    const token = Cookies.get('jwtToken');
    const memberId = Cookies.get('memberId');

    return token && memberId ? { token, memberId } : undefined;
  } catch {
    return undefined;
  }
};

export const saveCredentials = ({ token, memberId }: SessionUser): void => {
  try {
    Cookies.set('jwtToken', String(token), cookieOptions);
    Cookies.set('memberId', String(memberId), cookieOptions);
  } catch {} // eslint-disable-line no-empty
};

// Remove legacy cookies if they're still present
// Should fix LAAS-5640 hopefully
const legacyCookieOptions = {
  path: '/',
  domain: 'streetshares.com',
  sameSite: 'strict' as CookieAttributes['sameSite'],
  secure: !config.devMode, // Local dev doesn't support HTTPS right now
};
Cookies.remove('jwtToken', legacyCookieOptions);
Cookies.remove('memberId', legacyCookieOptions);
Cookies.remove('userId', legacyCookieOptions);
