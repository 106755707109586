import { makeStyles, Grid, Box, Container, Typography } from '@material-ui/core';
import { darken, lighten } from 'polished';
import { Footer, SerifTypography } from '@streetshares/frontend-common';

import { HeaderInfo } from 'components/Header/HeaderInfo';
import { Header } from 'components/Header';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  page: {
    height: '100%',
    margin: 'auto',
    backgroundColor: palette.background.paper,
    maxWidth: breakpoints.values.lg + 220,
  },
  pageContainer: {
    flexGrow: 1,
    backgroundColor:
      palette.type === 'light' ? lighten(0.11, palette.info.light) : darken(0.03, palette.background.default),
  },
  pageHeader: {},
  main: {
    width: '100%',
    padding: '0px', // flush with edges so that individual screens can be flush if they want to
  },
}));

type Props = {
  title?: string;
  description?: string;
  showAppHeader?: boolean;
  showPageHeader?: boolean;
  showTitleInBody?: boolean;
};
export const LayoutNoWayfinder: React.FC<Props> = ({
  title = null,
  description = null,
  showAppHeader = true,
  showPageHeader = false,
  showTitleInBody = true,
  children,
}) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.page}>
      <Grid item xs={12} sm md lg xl>
        {showAppHeader && <Header />}

        <main id="main-content">
          {showPageHeader && <HeaderInfo title={title} />}

          <Grid item className={classes.pageContainer}>
            {title && showTitleInBody && (
              <Container maxWidth="md">
                <Grid item className={classes.pageHeader}>
                  <Box pt={1.5} pb={1}>
                    <Typography variant="h4" gutterBottom>
                      {title}
                    </Typography>
                    {description && (
                      <SerifTypography color="textSecondary" variant="h5" gutterBottom>
                        {description}
                      </SerifTypography>
                    )}
                  </Box>
                </Grid>
              </Container>
            )}

            <Grid lg={12} xl={12} item className={classes.main}>
              <Box>{children}</Box>
            </Grid>
          </Grid>
        </main>

        <Footer />
      </Grid>
    </Grid>
  );
};
