import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { SystemStatusRes } from '@streetshares/frontend-common/dist/components/Layout/Header/typings';
import { isEqual } from 'lodash';

import { fetchSystemStatus } from 'api/systemStatusApi';

type SystemStatus = {
  status?: SystemStatusRes;
  statusCode: number;
  error: Error | null;
};

export function useSystemStatus(): SystemStatus {
  const location = useLocation();
  const [state, setState] = useState<SystemStatus>({ status: { status: 'operational' }, statusCode: 0, error: null });

  useEffect(() => {
    fetchSystemStatus()
      .then((data: SystemStatusRes) => {
        if (!isEqual(data, state?.status)) {
          setState({ status: data, statusCode: 0, error: null });
        }
      })
      .catch((error: Error) => {
        if (state?.status !== undefined) {
          setState({ status: undefined, statusCode: 0, error });
        }
      });
  }, [location?.pathname, state?.status]);

  return state;
}
