import { Container, makeStyles } from '@material-ui/core';

import { useBranding } from 'branding/useBranding';
import { LayoutNoWayfinder } from 'components/Layout/LayoutNoWayfinder';
import { setMetaDescription } from 'utils/utils';

import { LoginForm } from './LoginForm';

const useStyles = makeStyles(({ breakpoints }) => ({
  container: {
    padding: '60px 45px 45px 45px',
    [breakpoints.down('sm')]: {
      padding: '60px 15px',
    },
  },
}));

export const LoginScreen: React.FC = () => {
  const classes = useStyles();
  const { partnerName = '' } = useBranding();

  setMetaDescription(`Sign in to access the ${partnerName} Hub where you can view and update your applications.`);

  return (
    <LayoutNoWayfinder showAppHeader>
      <Container maxWidth="md" className={classes.container}>
        <LoginForm />
      </Container>
    </LayoutNoWayfinder>
  );
};
