import { ApplicationStatus } from 'Types/app';

export const ApplicationStatusType: Record<ApplicationStatus, ApplicationStatus> = {
  NEEDS_ATTENTION: 'NEEDS_ATTENTION',
  PRE_BOARDING: 'PRE_BOARDING',
  PRE_SUBMISSION: 'PRE_SUBMISSION',
  READY_FOR_DECISION: 'READY_FOR_DECISION',
  READY_FOR_FINAL: 'READY_FOR_FINAL',
  READY_FOR_REVIEW: 'READY_FOR_REVIEW',
  READY_TO_CLOSE: 'READY_TO_CLOSE',
  WAITING_FOR_ANALYSIS: 'WAITING_FOR_ANALYSIS',
  WAITING_FOR_APPLICANT: 'WAITING_FOR_APPLICANT',
  WAITING_FOR_ACCEPTANCE: 'WAITING_FOR_ACCEPTANCE',
  COMPLETE: 'COMPLETE',
  DECLINED: 'DECLINED',
  EXPIRED: 'EXPIRED',
  INELIGIBLE: 'INELIGIBLE',
  WITHDRAWN: 'WITHDRAWN',
  WAITING_FOR_MISSING_DOCUMENTS: 'WAITING_FOR_MISSING_DOCUMENTS',
};
