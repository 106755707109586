import useSWR from 'swr';

import { config } from 'config';
import { ProductConfig } from 'Types/app';

export const useProductConfig = (productName: string): Record<'productConfig', ProductConfig> => {
  const { data } = useSWR<ProductConfig>(
    `${config.apiBaseURL}/member/v2/laas/organizations/current/products/${productName}/config`,
  );

  return { productConfig: data ?? { settings: {} } };
};
